import { ComingSoon } from "../Icons/ComingSoon";
import { Details } from "../Icons/Details";
import { GELogo } from "../Icons/GELogo";
import { LockKey } from "../Icons/LockKey";
import { TDLogo } from "../Icons/TDLogo";
import { TrandscendIcon } from "../Icons/TrandscendIcon";
import Airtravel from "../assets/AirTravel.webp";
import ImageComparisonImage from "../assets/BladeInspection.webp";
import CommandApprover from "../assets/Command Approver.webp";
import Transcend from "../assets/Transcend.webp";

// import Resume from "../../src/Resume.pdf"
// import { CaseStudy } from "../components/layouts/CaseStudyList";

export const navLinks = [
  {
    id: 0,
    name: "RESUME",
    href: "resume.pdf",
  },
];

export const Case = [
  {
    id: 0,
    name: " GE Vernova  - Integration of Image comparison in Drone blade inspections",
    client: "GE Renewable",
    clientIcon: <GELogo />,
    role: "Enterprise Product Design",
    desc: "Implementation of Blade Image Comparison, which eliminated the need and cost for third-party software.  ",
    img: ImageComparisonImage,
    path: "/ImageComparison",
    status: "VIEW CASE STUDY",
    statusIcon: <LockKey />,
  },
  {
    id: 1,
    name: " GE Renewables - Command Approver, Energy farm command handlers",
    client: "GE Renewable",
    clientIcon: <GELogo />,
    role: "Enterprise Product Design",
    desc: " Discovery and design of a tool which allowed clients to handle the commands sent on energy farm. Command approver helps clients to decrease the downtime of assets",
    img: CommandApprover,
    path: "",
    status: "COMING SOON",
    statusIcon: <ComingSoon />,
  },
  // {
  //   id: 2,
  //   name: "Storage Energy UXR",
  //   client: "GE Renewable",
  //   clientIcon: <GELogo />,
  //   desc: "The strategy and digital roadmap for integrating energy storage need in lifespan through a research based discovery",

  //   // company: "Think Design",
  //   // duration: "1 Year",
  //   role: "UX RESEARCH / ENTERPRISE / B2B",
  //   img: storage,
  //   // path: "/AirTravelResearchC2",
  //   status: "COMING SOON",
  //   statusIcon: <ComingSoon />,
  // },
  {
    id: 3,
    name: "Transcend - Revamp of Collateral Management System",
    client: "Transcend Street",
    clientIcon: <TrandscendIcon />,
    desc: "Reimagined the experience and interface of Transcend's collateral management process. Story of how we gave  a 15-year-old product a new vibe.",
    role: "Enterprise Product Design",
    img: Transcend,
    path: "/Trandscend",
    status: "VIEW CASE STUDY",
    statusIcon: <LockKey />,
  },

  // {
  //   id: 4,
  //   name: "Air Travel Research",
  //   client: "Think Design",
  //   clientIcon: <TDLogo />,
  //   desc: "A research & design documentation on improving the air traveling experience",
  //   role: "UX Research",
  //   img: Airtravel,
  //   path: "/AirTravelResearchC2",
  //   status: "VIEW CASE STUDY",
  //   statusIcon: <Details />,
  // },
  // {
  //   id: 5,
  //   name: "Covid Test Management",
  //   client: "Think Design",
  //   clientIcon: <TDLogo />,
  //   desc: "Food can create the connections we crave—even when were apart,Sharebite, a food ordering platform designed for high performance.Sharebite, a food ordering platform designed for high performanc",
  //   role: "UI Designer",
  //   img: Project1,
  //   path: "/CovidCare",
  //   status: "View Case Study",
  //   statusIcon: <Details />,
  // },
];
