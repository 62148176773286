import { colors } from "@nextui-org/theme";
import styled from "styled-components";

export const HContainer = styled.div`
  margin-left: 16px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
`;
export const LContainer = styled.div`
  display: column;
`;

export const GridCard = styled.div`
  background-color: hsl(var(--nextui-background3));
  height: auto;
  width: 314px;
  display: column;
  padding: 24px;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;

  border: solid 1px;
  border-color: hsl(var(--nextui-background2));
`;

export const HeroImage = styled.img`
  object-fit: "cover";
  @media (max-width: 720px) {
    width: 90vw;
    object-fit: cover;
  }
`;

export const MContainer = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: top;
  /* background-color: red; */
  width: 40vw;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 80vw;
    font-size: 0.8rem;
    gap: 1rem;
  }
`;

export const StickyContainer = styled.div`
  /* position: sticky; */
  top: 0;
  background: rgba(10, 10, 10, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  padding-left: 2rem;
  z-index: 20;

  height: 8vh;
  margin-top: 3rem;
  /* border-bottom: 1px solid #505050; */
`;

export const Highlight = styled.a`
  font-weight: normal;
  color: hsl(var(--nextui-foreground1));
  width: 70%;
  padding-left: 0.4rem;
  /* padding: 10px 3px 3px 10px; */

  /* font-weight: normal; */
`;

export const CContainer = styled.div`
  display: grid;
  /* justify-content: space-evenly; */
  gap: 1.5rem;
  align-items: start;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    width: 80vw;
    font-size: 0.8rem;
    gap: 1rem;
  }
`;

export const PContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: space-evenly;
  gap: 64px;
  width: 92vw;
  margin-top: 6rem;
  background-color: "blue";

  @media (max-width: 720px) {
    gap: 2.5rem;
    width: 90vw;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  /* background-color: red; */
  flex-direction: column;
  width: 92vw;

  @media (max-width: 720px) {
    display: flex;
    width: 90vw;
  }
`;

export const ListContainer = styled.div`
  display: grid;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

export const TitleCircle = styled.div`
  background-color: hsl(var(--nextui-title));
  height: 6px;
  width: 13px;
  border-radius: 100px;
`;

// Text Styles **********************************************

export const GiantHeading = styled.h1`
  font-size: 40px;
  text-align: left;
  color: hsl(var(--nextui-foreground1));
  font-weight: bolder;
  width: 100%;
  font-family: MonoTypeSabon;

  @media (max-width: 720px) {
    font-size: 20px;
  }
`;

export const Heading = styled.h1`
  line-height: 32px;
  font-family: MonotypeSabon;
  font-size: 26px;
  text-align: left;
  color: hsl(var(--nextui-foreground1));
  font-weight: bolder;
  width: 65%;
  @media (max-width: 720px) {
    width: 90vw;
    font-size: 16px;
  }
`;
export const Subheading = styled.h2`
  color: hsl(var(--nextui-foreground1));
  font-size: 16px;
  font-weight: normal;
  @media (max-width: 720px) {
    font-size: 14px;
  }
`;
export const Body = styled.p`
  color: hsl(var(--nextui-foreground2));
  font-size: 16px;
  font-weight: normal;
  width: 60%;
  @media (max-width: 720px) {
    width: 100%;
    font-size: 14px;
  }
`;
export const Body2 = styled.p`
  color: hsl(var(--nextui-foreground1));
  font-size: 16px;
  font-weight: normal;
  width: auto;
  max-width: 30vw;
  @media (max-width: 720px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

export const Subtitle = styled.a`
  font-style: italic;
  color: hsl(var(--nextui-foreground1));
  font-weight: bold;
  font-size: 18px;
`;

export const Title = styled.h4`
  color: hsl(var(--nextui-foreground1));
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Avenir;

  @media (max-width: 720px) {
    font-size: 14px;
    width: auto;
  }
`;
export const SectionHeader = styled.h3`
  color: hsl(var(--nextui-title));
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Avenir;
`;

export const List = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 2rem; /* Adjust this value based on your design */
  text-indent: -1.5rem;
  /* text-indent: 1rem; */
  color: hsl(var(--nextui-foreground2));
  font-size: 16px;
  width: 50vw;
  @media (max-width: 720px) {
    width: 80vw;
    font-size: 0.8rem;
    gap: 1rem;
  }
`;

// Containers

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 720px) {
    display: flex;

    gap: 0.1rem;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
  /* gap: 6rem; */
`;
export const NoteContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  display: grid;
  grid-template-columns: auto auto;
  flex-direction: column;
  gap: 60px;
  width: 100%;
`;
// Spacers ***********

export const Sp0 = styled.div`
  height: 4px;
`;

export const Sp1 = styled.div`
  height: 8px;
  @media (max-width: 720px) {
    height: 2px;
  }
`;
export const Sp2 = styled.div`
  height: 16px;
  @media (max-width: 720px) {
    height: 8px;
  }
`;
export const Sp3 = styled.div`
  height: 24px;
`;

export const Sp4 = styled.div`
  height: 32px;
`;
export const Sp5 = styled.div`
  height: 48px;
`;

export const Sp6 = styled.div`
  height: 60px;
`;

export const Divider = styled.div`
  width: auto;
  height: 2px;
  background-color: hsl(var(--nextui-background2));
`;
