import Showreel from "../../../src/assets/Showreel_-Web-gallery-[remix].gif";
import animationData from "../../../src/assets/Showreel_-Web-gallery-[remix].json";
import { HomeIcon } from "../../Icons/HomeIcon";
import CustomerFace, { EngineerFace } from "../../Icons/PersonaAvatar";
import BonifaceAvatar from "../../assets/P4ImageComparison/BonifaceAvatar.png";
import IshaniAvatar from "../../assets/P4ImageComparison/IshaniAvatar.png";
import ScottAvatar from "../../assets/P4ImageComparison/Scott Avatar.png";
import PunitAvatar from "../../assets/P4ImageComparison/punitAvatar.png";
import C1Showcase from "../../assets/Transcend/C1ShowCase.png";
import C1Visual1 from "../../assets/Transcend/C1Visual1.webp";
import C1Visual2 from "../../assets/Transcend/C1Visual2.webp";
import C2ShowCase from "../../assets/Transcend/C2Show Case.png";
import C2visual1 from "../../assets/Transcend/C2Visual1.webp";
import C2visual2 from "../../assets/Transcend/C2visual2.webp";
import ComponentShowcase from "../../assets/Transcend/ComponentShowcase.webp";
import ComponentShowcaseC2 from "../../assets/Transcend/ComponentShowcaseC2.webp";
import Components from "../../assets/Transcend/Components.webp";
import ComponentsC2 from "../../assets/Transcend/ComponentsC2.webp";
import Dashboard from "../../assets/Transcend/Dashboard.webp";
import showcase from "../../assets/Transcend/Frame 23131.webp";
import Iconography from "../../assets/Transcend/Iconography.webp";
import IconsC2 from "../../assets/Transcend/Iconsc2.webp";
import IncreasedRealEstate from "../../assets/Transcend/Increase Real Esate.webp";
import MultiPurposePanel from "../../assets/Transcend/Multipurpose Panel.webp";
import OldLook from "../../assets/Transcend/OldLook.webp";
import RoyalBlueHero from "../../assets/Transcend/Royal Blue.webp";
import SummaryView from "../../assets/Transcend/Summery View.webp";
import TypoGraphy from "../../assets/Transcend/Typography.webp";
import TypographyC2 from "../../assets/Transcend/TypographyC2.webp";
import FlamingoHero from "../../assets/Transcend/flamingo Hero.webp";
// import { HomeButton } from "../Buttons";
import {
  HContainer,
  HeroImage,
  Subheading,
  Heading,
  MContainer,
  Title,
  Body,
  Highlight,
  Subtitle,
  List,
  IntroContainer,
  PContainer,
  CContainer,
  ColumnContainer,
  TitleCircle,
  TitleContainer,
  Sp0,
  Sp1,
  Sp2,
  Sp3,
  Sp4,
  Sp5,
  NoteContainer,
  GiantHeading,
  RowContainer,
  Divider,
  Body2,
  GridCard,
} from "../../styles/CaseStudyDetail.styled";
import { SectionHeader } from "../../styles/CaseStudyDetail.styled";
import { Sp6 } from "../../styles/CaseStudyDetail.styled";
import { ButtonV2 } from "../Buttons";
import { IconButton } from "../Buttons";
import { InfoCard } from "../InfoCard";
import PasswordProtectedPage from "../PasswordModal";
import Confirmation from "../PasswordModal";
import { ColorSwatch } from "../ui/ColorSwatch";
import { FloatingNav } from "../ui/FloatingToolbar";
import { AnimatedTooltip } from "../ui/Tooltip";
import { useInView } from "framer-motion";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";
import { React } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const people = [
  {
    id: 1,
    name: "Punit Gupta(Me)",
    designation: "Product Designer",
    image: PunitAvatar,
  },
  {
    id: 2,
    name: "Rohini",
    designation: "Design Lead",
    image: IshaniAvatar,
  },
  {
    id: 3,
    name: "Ankit Ranka",
    designation: "Product Designer",
    image: BonifaceAvatar,
  },
  {
    id: 4,
    name: "Prakash",
    designation: "Product Designer",
    image: ScottAvatar,
  },
];

export const Trandscend = () => {
  const scrollRefs = {
    Context: useRef(null),
    ProblemStatement: useRef(null),
    Impact: useRef(null),
    Concept1: useRef(null),
    Concept2: useRef(null),

    // Add refs for all sections you need
  };
  const handleScroll = (section) => {
    scrollRefs[section].current.scrollIntoView({ behavior: "smooth" });
  };
  const navigation = useNavigate();
  const white = useRef(null);
  const WhiteInView = useInView(white);
  return (
    <PasswordProtectedPage>
      <>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
          }}
          // className="bg-white"
        >
          <motion.div className="h-[13%] justify-between flex  w-[92vw] bg-background rounded rounded-2 p-10 border-background2 border-[1px] fixed bottom-4 z-[90]">
            {/* <motion.div
            style={{ scaleX }}
            className=" flex flex-row justify-between items-center w-[100%] h-[100%] bg-background2 px-[2rem] origin-[0%] top-0 left-0 right-0 absolute z-[-1]  "
          ></motion.div> */}
            <AnimatePresence>
              <motion.div
                className="flex text-[14px] font-normal justify-between items-center w-full max-w-1xl sm:items-center  "
                layout
                // transition={{ duration: 10, ease: "linear" }}
              >
                <div className="flex  text-foreground1 justify-between items-center w-full max-w-1xl sm:hidden ">
                  <IconButton
                    icon={<HomeIcon />}
                    action={() => navigation("/")}
                  />
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Context"
                      action={() => handleScroll("Context")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Impact"
                      action={() => handleScroll("Impact")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Problem"
                      action={() => handleScroll("ProblemStatement")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Design Language"
                      action={() => handleScroll("Concept1")}
                    />
                  </motion.div>
                </div>
                {/* <FloatingNav /> */}
              </motion.div>
            </AnimatePresence>
          </motion.div>
          <div className=" flex w-[100vw] h-[90vh] bg-background3 items-center justify-center">
            <HeroImage
              src={showcase}
              style={{ height: "auto", width: "60vw" }}
            />
          </div>
          {/* <img src={Showreel} style={{ height: "auto", width: "100vw" }}/> */}
          {/* <Lottie animationData={animationData}/> */}
          {/* <Confirmation /> */}

          <IntroContainer>
            <Sp5 />
            <ColumnContainer>
              <GiantHeading>
                Trandscend: Revamp of Collateral Management System
              </GiantHeading>
              <Sp1 />
              <Subheading className="text-foreground2">
                A redesign which helped Transcend to shortlisted for Collateral
                Management Solution of the Year 2022
              </Subheading>
              <Sp4 />
              <Divider />
              <Sp4 />
            </ColumnContainer>
            <ColumnContainer>
              <Title>My Role</Title>
              <Sp1 />
              <Body className="text-foreground1">
                As part of Transcend design team, I was responsible for the
                visual design of Transcend platform. In this project I designed
                concept for transcend's new UI.
              </Body>

              <Sp2 />
              <Body className="text-foreground1">
                I was in charge of Concept ideation, component Designs,
                wireframing and visual designs.
              </Body>
              <Sp4 />
              <Divider />
            </ColumnContainer>
            <Sp4 />
            <RowContainer>
              <ColumnContainer style={{ width: "100%" }}>
                <Title>Team</Title>
                <Sp2 />
                <div className="flex flex-row  items-start  w-full">
                  <AnimatedTooltip items={people} />
                </div>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Timeline</Title>
                <Sp2 />
                <Body style={{ width: "auto" }}>2 Month</Body>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Tools</Title>
                <Sp2 />
                <Body style={{ width: "auto" }}>Figma </Body>
                <Body style={{ width: "auto" }}>Figjam </Body>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Tasks</Title>
                <Sp2 />

                <Body style={{ width: "auto" }}>Concept & Layout Designs</Body>

                <Body style={{ width: "auto" }}>Wireframing & Visuals</Body>

                <Body style={{ width: "auto" }}>
                  Presenting To Stakeholders{" "}
                </Body>
                <Body style={{ width: "auto" }}>Data Visualization </Body>
              </ColumnContainer>
            </RowContainer>
          </IntroContainer>

          <PContainer>
            <InfoCard
              text={
                "This is a UI Focused case study. Reading context before the main concepts is recommended. Due to NDA, I have only showed limited designs and wireframes showcasing my thought process. "
              }
            />
            <ColumnContainer ref={scrollRefs["Context"]}>
              <TitleContainer>
                <TitleCircle />
                <SectionHeader> Context (Must Read) </SectionHeader>
              </TitleContainer>
              <Sp2 />
              <Heading>
                The global collateral management market was valued at
                approximately $1.5 billion in 2021 and is expected to grow
                significantly.
              </Heading>
              <Sp6 />
              <Body>
                Collateral management involves monitoring and managing assets
                pledged as security for financial transactions, ensuring they
                meet margin requirements and reduce counterparty risk
              </Body>
              <Sp0 />
              <Body>
                Let’s make it easy, Collateral management is like a safety net
                in finance.
                <Highlight>
                  Imagine you borrow money from a friend, and to make sure you
                  pay it back, you give them something valuable, like your
                  phone, as a promise. If you don’t pay back, they can keep the
                  phone, That is call collateral bond. Transcend is a Collateral
                  Management Tool.
                </Highlight>
              </Body>
            </ColumnContainer>
            <Sp6 />

            {/* <MContainer>
            <ColumnContainer>
              <Subtitle>Transcend before meeting us 🧐 </Subtitle>
              <HeroImage src={C1Showcase} />
            </ColumnContainer>
            <ColumnContainer>
              <Subtitle>Transcend After meeting us 🤩</Subtitle>
              <HeroImage src={C1Showcase} />
            </ColumnContainer>
          </MContainer> */}

            <ColumnContainer ref={scrollRefs["Impact"]}>
              <TitleContainer>
                <TitleCircle />
                <SectionHeader> Impact </SectionHeader>
              </TitleContainer>
              <Sp2 />
              <Heading>
                Transcend secured undisclosed amount
                <a
                  className="underline font-[inherit] text-foreground1"
                  href="https://www.transcendstreet.com/transcend-secures-investment-from-citi-to-accelerate-global-deployment-of-enterprise-wide-inventory-optimization-solutions/"
                >
                  {" "}
                  funding from CITI Bank{" "}
                </a>
                in Nov 2023.
              </Heading>
              <Sp4 />
              <Body>
                The investment was made through Citi’s Strategic Investments
                arm, which invests in innovative fintech companies globally that
                are strategically aligned to Citi’s institutional businesses.
                The revamp helped investors and management to gauge the future
                of transcend.
              </Body>
            </ColumnContainer>
            <Sp6 />

            <ColumnContainer ref={scrollRefs["ProblemStatement"]}>
              <TitleContainer>
                <TitleCircle />
                <SectionHeader> Problem with transcend </SectionHeader>
              </TitleContainer>
              <Sp2 />
              <Heading>
                Transcend's core was longing for a refresh, needing a new look
                to stay relevant and connect better with today’s users.
              </Heading>
              <Sp6 />

              <div className="grid grid-cols-2 grid-rows-2 gap-[60px] flex-row  justify-between w-[max] py-[48px] px-[24px] rounded border-[2px] border-background2">
                <div className="w-[max]">
                  <Title className="text-foreground1">
                    {" "}
                    Limited Accessibility
                  </Title>
                  <Sp1 />
                  <Body style={{ width: "auto" }}>
                    {" "}
                    The native application restricted access, hindering
                    onboarding of new users. The native application, entrenched
                    in the realm of Windows and MacOS, acted as a fortress with
                    a single key.Its exclusivity restricted the influx of new
                    users, dampening the prospects of growth and expansion.
                  </Body>
                </div>

                <div className="w-[auto]">
                  <Title className="text-foreground1">
                    Lack of Storytelling - Complexity of Data
                  </Title>
                  <Sp1 />

                  <Body style={{ width: "100%" }}>
                    {" "}
                    Inside Transcend, users found themselves overwhelmed by the
                    sheer amount of data. Without any helpful visuals, making
                    sense of all the information became a real struggle.Users
                    felt lost in a sea of numbers, unable to find the insights
                    they needed to make decisions.
                  </Body>
                </div>
                <div className="w-[auto]">
                  <Title className="text-foreground1">
                    {" "}
                    Outdated Interface
                  </Title>
                  <Sp1 />

                  <Body style={{ width: "100%" }}>
                    {" "}
                    Transcend's look and feel seemed stuck in the past. Its
                    design felt old-fashioned and out of touch with what modern
                    users expect. This outdated interface made it difficult to
                    attract new clients who were looking for something more
                    up-to-date and visually appealing.
                  </Body>
                </div>
              </div>
            </ColumnContainer>
            <Sp6 />
            {/* <ColumnContainer>
            <Sp6 />

            <TitleContainer>
              <TitleCircle />
              <SectionHeader> quick look onto users </SectionHeader>
            </TitleContainer>
            <Sp2 />
            <Heading>
              TRUST is the key in finance, to gain trust, we had to understand
              the users
            </Heading>
            <Sp4 />
            <Body>
              Understanding the unique needs and preferences of these entities
              played a pivotal role in shaping Transcend's redesign strategy.
            </Body>
            <Sp2 />
            <div className="flex justify-between items-center  py-[24px] px-[24px] rounded border-[2px] border-background2 ">
              <div className="flex flex-col justify-between items-center gap-[48px] my-[24px] mx-[32px]">
                <EngineerFace />
                <div className="flex flex-col gap-[16px] ">
                  <div className="text-center text-foreground2 font-[14px] font-bold">
                    COUNTER PARTY
                  </div>
                  <div className="text-center text-foreground2 w-[40vw] ">
                    Including banks, insurance companies, broker-dealers, hedge
                    funds, pension funds, asset managers, and large
                    corporations.
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-[60px] my-[24px] mx-[32px]">
                <CustomerFace />
                <div className="flex flex-col gap-[16px] ">
                  <div className="text-center text-foreground2 font-[14px] font-bold">
                    BUY-SIDE FIRM
                  </div>
                  <div className="text-center text-foreground2  w-[40vw]  ">
                    Particularly those reliant on manually entered payment and
                    settlement instructions.
                  </div>
                </div>
              </div>
            </div>
          </ColumnContainer> */}
            <ColumnContainer>
              <Heading>The Older Look</Heading>
              <Sp2 />
              <Body>
                The initial phase involved extensive demos from the client,
                capturing screenshots, and recording calls to grasp the
                intricacies of Transcend's workflow. This immersion helped
                identify existing design patterns, understand user pain points,
                and define unique selling points (USPs) for the revamped
                product.
              </Body>
              <Sp6 />
              <HeroImage src={OldLook} />
            </ColumnContainer>

            <Sp6 />

            {/* { CONCEPT  STARTS HERE #####################################################
        #
        #
        #
        #
        #
        #
        #
        ##
        #
        #
        #
        
        
        
        } */}

            {/* Older Design start here ################################ */}
          </PContainer>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
          }}
        >
          <PContainer>
            <RowContainer>
              <ColumnContainer>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> UI Design </SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading ref={scrollRefs["Concept1"]}>The New Look</Heading>

                <Sp2 />
                <Sp2 />

                <Sp5 />
                <Title> Flamingo : The Dark</Title>
                <Sp3 />
                <Body2 style={{ color: "hsl(var(--nextui-foreground2))" }}>
                  In Flamingo User experience is enhanced by adding Interactions
                  to the table which in turn makes user to view more data in
                  less real estate. Retaining the traditional tabular format,
                  latest trends in design & technology are applied to enhance
                  accessibility which added value to the product on the whole.
                </Body2>
              </ColumnContainer>

              <ColumnContainer>
                <HeroImage src={FlamingoHero} />
              </ColumnContainer>
            </RowContainer>
            <Sp5 />

            <ColumnContainer>
              <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                Principals
              </Heading>
              <Sp5 />
              <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                Flamingo has four pillar principles, each component of the
                concept is derived from these pillars. The idea was to make a
                modern UI but not so flashy.
              </Body>
              <Sp5 />
              <RowContainer style={{ gap: "2rem" }}>
                <GridCard>
                  <Title>Proactive</Title>
                  <Sp2 />
                  <Body
                    style={{
                      width: "283px",
                      color: "hsl(var(--nextui-foreground2))",
                    }}
                  >
                    Keeping the use cases in mind the UI should be handy for any
                    type of task. Ability to multitask has been added for ease
                    of use.
                  </Body>
                </GridCard>
                <GridCard>
                  <Title>Rich but Not Jazzy</Title>
                  <Sp2 />
                  <Body
                    style={{
                      width: "283px",
                      color: "hsl(var(--nextui-foreground2))",
                    }}
                  >
                    While the idea was to make a modern UI but the UI should
                    calm and should allow users to digest easily.
                  </Body>
                </GridCard>
                <GridCard>
                  <Title>Helpful</Title>
                  <Sp2 />
                  <Body
                    style={{
                      width: "283px",
                      color: "hsl(var(--nextui-foreground2))",
                    }}
                  >
                    The domain has so many modules and functionalities, UI
                    should be able to guide user through every journey.
                  </Body>
                </GridCard>
                <GridCard>
                  <Title>Accessibility </Title>
                  <Sp2 />
                  <Body style={{ width: "283px" }}>
                    The target audience of the UI vary from people aged 30 to
                    80. Accessibility is non negotiable in all concepts.
                  </Body>
                </GridCard>
              </RowContainer>
              <Sp5 />
            </ColumnContainer>
            <Sp5 />
            <ColumnContainer>
              <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                Colours
              </Heading>
              <Sp5 />
              <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                For the first concept, we chose the company logo colour as the
                primary colour. The idea was to keep the brand identity alive in
                the new design. For the dark theme we decided to go with dark
                blue shades as background & foreground
              </Body>
              <Sp5 />
              <Sp5 />

              <ColumnContainer>
                <ColorSwatch
                  name={"FLAMINGO"}
                  hex={"#E06946"}
                  rgb={"RGB: 244:105:70 "}
                  text={"#fff"}
                />
              </ColumnContainer>
              <Sp5 />
              <Sp5 />
              <Sp5 />

              <RowContainer>
                <ColorSwatch
                  name={"Kelly Green"}
                  hex={"#36A012"}
                  rgb={"RGB: 54:160:18  "}
                  text={"#fff"}
                />{" "}
                <ColorSwatch
                  name={"Maya Blue"}
                  hex={"#55B8F8"}
                  rgb={"RGB: 85:184:248 "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"Blue Violet "}
                  hex={"#963FF5"}
                  rgb={"RGB: 150:63:245  "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"Falu Red"}
                  hex={"#8B0D0D"}
                  rgb={"RGB: 139:13:13  "}
                  text={"#fff"}
                />
              </RowContainer>
              <Sp5 />
              <RowContainer>
                <ColorSwatch
                  name={"Cloud Burst"}
                  hex={"#333A53"}
                  rgb={"RGB: 51:58:83  "}
                  text={"#fff"}
                />{" "}
                <ColorSwatch
                  name={"Midnight Express"}
                  hex={"#1d1f36"}
                  rgb={"RGB: 29:31:54  "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"Midnight"}
                  hex={"#191c34"}
                  rgb={"RGB: 25:28:52 "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"Biscay"}
                  hex={"#303453"}
                  rgb={"RGB: 48:52:83  "}
                  text={"#fff"}
                />
              </RowContainer>
              <Sp5 />
              <Sp5 />
              <RowContainer>
                <ColorSwatch
                  name={"Grey Suit"}
                  hex={"#8F9097"}
                  rgb={"RGB:143:144:151  "}
                  text={"#fff"}
                />{" "}
                <ColorSwatch
                  name={"Light Grey"}
                  hex={"#D2d2d2"}
                  rgb={"RGB:210:210:210  "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"Gainsboro"}
                  hex={"#E3E3E3"}
                  rgb={"RGB:227:227:227 "}
                  text={"#fff"}
                />
                <ColorSwatch
                  name={"White"}
                  hex={"#ffffff"}
                  rgb={"RGB:255:255:255 "}
                  text={"#fff"}
                />
              </RowContainer>
              <Sp5 />
              <Sp5 />
              <Sp5 />
            </ColumnContainer>
            <ColumnContainer>
              <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                Typography
              </Heading>
              <Sp5 />
              <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                Roboto has a dual nature. It has a mechanical skeleton and the
                forms are largely geometric. At the same time, the font features
                friendly and open curves. While some grotesks distort their
                letterforms to force a rigid rhythm, Roboto doesn’t compromise,
                allowing letters to be settled into their natural width. This
                makes for a more natural reading rhythm more commonly found in
                humanist and serif types.
              </Body>
              <Sp5 />
              <HeroImage src={TypoGraphy} />
            </ColumnContainer>
            <Sp5 />
            <Sp5 />

            <ColumnContainer>
              <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                Iconography
              </Heading>
              <Sp5 />
              <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                This minimalist icon style excels in clarity and versatility,
                offering quick recognition and seamless integration across
                various digital interfaces. Making it an ideal choice for
                modern, user-friendly design. Most of the Icons have been taken
                from a Icon library. There were some exceptional icon which we
                had to design like liquidity.
              </Body>
              <Sp5 />
              <Sp5 />
              <HeroImage src={Iconography} />
            </ColumnContainer>
            <Sp5 />
            <Sp5 />
      

            <RowContainer>
              <ColumnContainer>
                <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                  Components
                </Heading>
                <Sp5 />
                <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                  Transcend is designed in figma. We followed the atomic design
                  approach to build the components and a living library around
                  it. Each component has been added to a common file where we
                  defined the properties for devs. There were around 200+
                  component built for all the modules. Every component was
                  designed to scale for small to large amount of data
                </Body>
              </ColumnContainer>

              <ColumnContainer>
                <HeroImage
                  src={ComponentShowcase}
                  style={{ height: "auto", width: "30vw" }}
                />
              </ColumnContainer>
            </RowContainer>

            <ColumnContainer>
              <HeroImage src={Components} />
            </ColumnContainer>
            <Sp5 />
            <Sp5 />
            <Sp5 />

            <ColumnContainer>
              <ColumnContainer>
                <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                  Unique Selling Points
                </Heading>
              </ColumnContainer>
              <Sp5 />

              <Title style={{ color: "#D2D0D0" }}>Summary View</Title>
              <Sp1 />
              <Body>
                The Summary view of entire data shown in table is represented on
                the top. These acts like actionable insights for the user.
                Proactively helps user to take decisions faster and act
                accordingly.
              </Body>
              <Sp3 />
              <HeroImage src={SummaryView} />
              <Sp5 />
              <Sp5 />

              <Title style={{ color: "#D2D0D0" }}>Dashboard</Title>
              <Sp1 />
              <Body>
                Summarized Information of all the modules are shown in this page
                to give an overview. Further we can redirect the user to each
                individual module depending on the need of the user.
              </Body>
              <Sp3 />
              <HeroImage src={Dashboard} />
              <Sp5 />
              {/* <Title style={{ color: "#D2D0D0" }}>
            Enhanced Filter Functionality
          </Title>
          <Sp1 />
          <Body>
            Real-estate of page is increased by including filter in a single
            cell instead of left panel. Filter functionality is more enhanced by
            showing what filters are selected in the form of chips upfront.
          </Body>
          <Sp3 />
          <HeroImage src={SummaryView} /> */}
              <Sp5 />
              <Sp5 />
            </ColumnContainer>
            <ColumnContainer>
              <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                Visual Design
              </Heading>
              <Sp5 />
              <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                We gave the Transcend application a fresh look with a sleek dark
                theme and a clean UI, enhanced by data visualization. The
                redesign emphasizes clarity, making clean information a key USP.
              </Body>
              <Sp5 />
              <HeroImage src={C1Visual1} />
              <Sp3 />
              <HeroImage src={C1Visual2} />
            </ColumnContainer>
            <Sp5 />

            <ColumnContainer>
              <ColumnContainer>
                <Heading style={{ color: "hsl(var(--nextui-foreground1))" }}>
                  Evaluation
                </Heading>
                <Sp5 />
              </ColumnContainer>
              <RowContainer>
                <ColumnContainer>
                  <Title>Pros</Title>
                  <Sp2 />
                  <Body2>
                    {" "}
                    Retaining the tabular structure makes it easy for existing
                    user to adapt.
                  </Body2>
                  <Sp2 />
                  <Body2>
                    {" "}
                    Can access other modules quickly from left menu.
                  </Body2>
                  <Sp2 />
                  <Body2> All the CTA is indicated in same color</Body2>
                  <Sp2 />
                  <Body2>
                    {" "}
                    Color contrast is evident. Colors indication for Difference
                    amount, new row added is legible.
                  </Body2>
                  <Sp2 />
                  <Body2>Font size & Text is easy to read. </Body2>
                </ColumnContainer>
                <ColumnContainer>
                  <Title>Cons</Title>
                  <Sp2 />
                  <Body2>
                    {" "}
                    Layout may not be the same for smaller desktop screens.
                    Responsiveness is difficult
                  </Body2>
                  <Sp2 />
                  <Body2>
                    Dedicated space to summary is using real estate.
                  </Body2>
                </ColumnContainer>
              </RowContainer>
            </ColumnContainer>
            <Sp5 />
            <Sp5 />
          </PContainer>
        </div>
      </>
    </PasswordProtectedPage>
  );
};
