import LightBulb from "../Icons/Gears";
import HeroHero from "../assets/HeroSection/HeroImage.png";
import Himalayaphoto from "../assets/HeroSection/Himalyasss.webp";
import { Navbar } from "../components/Navbar";
import {
  TextRevealCard,
  TextRevealCardDescription,
  TextRevealCardTitle,
} from "../components/ui/TextReveal";
import {
  Body,
  CContainer,
  Sp1,
  Sp2,
  Sp3,
  Sp4,
  Sp6,
  TitleCircle,
} from "../styles/CaseStudyDetail.styled";
import {
  Header,
  MainImage,
  Para,
  ParentWrapper,
} from "../styles/HeroSection.styled";
import { MenuButton } from "../styles/Navbar.styled";
import { cn } from "../utils/cn";
import Button, { Status, ThemeSwitch, ThemeSwitcher } from "./Buttons";
import { HeroAnimation } from "./layouts/HeroAnimation";
import Example, { BubbleText } from "./ui/BubbleText";
import Clock from "./ui/LocalTime";
import { Spotlight } from "./ui/Spotlight";
import { useGSAP } from "@gsap/react/dist";
import { Chip } from "@nextui-org/react";
import { Draggable } from "gsap/dist/Draggable";
import { gsap } from "gsap/dist/gsap";
import React, { useRef } from "react";

gsap.registerPlugin(Draggable);

export const HeroSection = () => {
  const dragItem = useRef();

  useGSAP(() => {
    // gsap.to(dragItem.current, { rotation: "-=360", duration: 3 });
    Draggable.create(".imageClass", { bounds: "body", inertia: true });
  }, []);
  return (
    <>
      <div className="h-[100vh] w-full rounded-md flex items-top pt-[4vw]   md:justify-left   antialiased  relative overflow-hidden border-0 border-[#151515] sm:pt-[8vh] sm:h-[80vh] ">
        <div className="  relative  w-full flex flex-col items-start ">
          <div className="flex  items-center gap-2 h-[2rem] sm:items-center ">
            <TitleCircle />
            <h1
              className=" horror-dark font-bold  uppercase   text-left text-title   "
              style={{
                fontFamily: "Avenir",

                fontSize: "14px",
              }}
            >
              Punit Gupta
              <br />
            </h1>
          </div>

          <BubbleText
            text={
              "Turning complex tech into something even your dog 🐶 won’t need a tutorial for."
            }
          />
          <Sp6 />
          <p className="text-foreground1 w-[40vw] text-[16px] font-normal  sm:w-[80vw] sm:text-[16px] ">
            I teach computers to understand humans better. I have spent 4 years
            in digital product design space while working on big tech B2B, B2C
            and Enterprise level products.
          </p>
          {/* I have spent 4 years
            in digital product design space while working on big B2B, B2C and
            Enterprise level products. */}
          <Sp2 />

          <div
            style={{
              display: "flex",
              alignItems: "center",

              gap: "1rem",
            }}
          >
            <Status input={` Senior UX Designer @  Think Design `} />
          </div>

          {/* <TextRevealCard
            text="I am perfect blend of "
            revealText=" Designing | Coding | Art 🤩"
          ></TextRevealCard> */}
        </div>
        {/* <HeroAnimation /> */}
      </div>
    </>
  );
};
