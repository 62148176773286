import styled from "styled-components";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  margin-bottom: 0.2vh;

  display: flex;
  flex-direction: row;
  /* background-color: black; */
  /* margin-right: 10rem; */
  justify-content: space-between;
  align-items: center;

  /* background-color: red; */

  @media (max-width: 720px) {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    background-color: red;
    z-index: 11;
    height: 200%;
    /* background-color: red; */
    transition: all 0.1s ease in;
    justify-content: space-between;
  }
`;

export const Logo = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: white;
`;

export const MenuIcon = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  padding-left: 1rem;
  padding-top: 1.3rem;
`;

export const NavMenuContainer = styled.div`
  /* margin-bottom: 10rem; */

  height: 90%;
  width: 100%;

  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  margin-right: 2rem;
  /* margin-right: 1rem; */
  /* gap: 1rem; */

  @media (max-width: 720px) {
    z-index: 200;
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-right: 0rem;

    /* background-color: red; */
  }
`;

export const MenuItem = styled.a`
  color: #fff;
  font-weight: bolder;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  /* padding-left: 1rem; */
  padding-top: 8px;

  @media (max-width: 720px) {
    align-items: center;
  }
`;

export const MenuButton = styled.h1`
  font-size: 1rem;
  font-weight: regular;

  cursor: pointer;
  font-family: PPNeueMontrealMono;
`;

export const CloseIcon = styled.a`
  display: none;

  @media (max-width: 720px) {
    display: block;
    color: white;
    padding-top: 10vh;
  }
`;
