import { HomeIcon } from "../../Icons/HomeIcon";
import {
  AnalyticsIcon,
  DroneIcon,
  Turbine,
  Turbine2,
} from "../../Icons/ImageComparisonIcons";
import CustomerFace, { EngineerFace } from "../../Icons/PersonaAvatar";
import Face from "../../Icons/PersonaAvatar";
import { Play } from "../../Icons/Play";
import { SunIcon } from "../../Icons/SunIcon";
import Background from "../../assets/P4ImageComparison/Background.png";
import BonifaceAvatar from "../../assets/P4ImageComparison/BonifaceAvatar.png";
import CA from "../../assets/P4ImageComparison/CA.webp";
import CoverImage from "../../assets/P4ImageComparison/Cover.webp";
import CurrentJourney from "../../assets/P4ImageComparison/Current Journey.webp";
import FV1 from "../../assets/P4ImageComparison/FV1.webp";
import FV2 from "../../assets/P4ImageComparison/FV2.webp";
import FV3 from "../../assets/P4ImageComparison/FV3.webp";
import FV4 from "../../assets/P4ImageComparison/FV4.webp";
import FV5 from "../../assets/P4ImageComparison/FV5.webp";
import FV6 from "../../assets/P4ImageComparison/FV6.webp";
import FV7 from "../../assets/P4ImageComparison/FV7.webp";
import FinalVisuals from "../../assets/P4ImageComparison/Image Comparison Flow.webp";
import InitialConcept from "../../assets/P4ImageComparison/Initial Concept.webp";
import IshaniAvatar from "../../assets/P4ImageComparison/IshaniAvatar.png";
import Layout from "../../assets/P4ImageComparison/Layout.webp";
import PMCall from "../../assets/P4ImageComparison/PM CALL.png";
import Skelton from "../../assets/P4ImageComparison/PM CALL.webp";
import ScottAvatar from "../../assets/P4ImageComparison/Scott Avatar.png";
import StoryMap from "../../assets/P4ImageComparison/StoryMap.webp";
import Wireframes from "../../assets/P4ImageComparison/Wireframes.webp";
//Images
import PunitAvatar from "../../assets/P4ImageComparison/punitAvatar.png";
import Home from "../../screens/Home";
import {
  Body,
  ColumnContainer,
  HContainer,
  Heading,
  HeroImage,
  Highlight,
  IntroContainer,
  List,
  NoteContainer,
  PContainer,
  Sp0,
  Sp1,
  Sp2,
  Sp3,
  Sp4,
  Sp5,
  Subheading,
  Subtitle,
  Title,
  TitleCircle,
  TitleContainer,
  MContainer,
  CContainer,
  RowContainer,
  GiantHeading,
  gridCard,
  SectionHeader,
  Sp6,
} from "../../styles/CaseStudyDetail.styled";
import { Divider } from "../../styles/CaseStudyDetail.styled";
import { ButtonV2, IconButton } from "../Buttons";
import PasswordProtectedPage from "../PasswordModal";
import Confirmation, { InitialFocus, PasswordModal } from "../PasswordModal";
import Confirmation2 from "../PasswordModal";
import { FloatingNav } from "../ui/FloatingToolbar";
import { Loader } from "../ui/Loader";
import { OutcomeCard } from "../ui/OutcomeCard";
import { AnimatedTooltip } from "../ui/Tooltip";
import { AnimatePresence, motion, useScroll, useSpring } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { React } from "react";
import { useNavigate } from "react-router-dom";

// Tooltip Data
const people = [
  {
    id: 1,
    name: "Punit Gupta(Me)",
    designation: "Product Designer",
    image: PunitAvatar,
  },
  {
    id: 2,
    name: "Ishani Kulkarni",
    designation: "Design Lead",
    image: IshaniAvatar,
  },
  {
    id: 3,
    name: "Chege Boniface",
    designation: "UX Manager",
    image: BonifaceAvatar,
  },
  {
    id: 4,
    name: "Scott",
    designation: "Product Manager",
    image: ScottAvatar,
  },
];

// Functions Start here
export const ImageComparison = (props) => {
  const scrollRefs = {
    Context: useRef(null),
    ProblemStatement: useRef(null),
    TargetPersona: useRef(null),
    Research: useRef(null),
    Ideation: useRef(null),
    VisualDesign: useRef(null),

    // Add refs for all sections you need
  };
  const handleScroll = (section) => {
    scrollRefs[section].current.scrollIntoView({ behavior: "smooth" });
  };
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 150,
    damping: 30,
    restDelta: 0.001,
  });
  const navigation = useNavigate();
  return (
    <>
      <PasswordProtectedPage>
        <div
          style={{
            justifyContent: "center",
            display: "flex",

            paddingBottom: "4rem",
          }}
          className="bg-background"
        >
          <div className=" flex w-[100vw] h-[90vh] bg-background3 items-center justify-center">
            <HeroImage
              src={CoverImage}
              style={{ height: "auto", width: "70vw" }}
            />
          </div>

          <motion.div className="h-[13%] justify-between flex  w-[92vw] bg-background rounded rounded-2 p-10 border-background2 border-[1px] fixed bottom-4 z-[90]">
            {/* <motion.div
            style={{ scaleX }}
            className=" flex flex-row justify-between items-center w-[100%] h-[100%] bg-background2 px-[2rem] origin-[0%] top-0 left-0 right-0 absolute z-[-1]  "
          ></motion.div> */}
            <AnimatePresence>
              <motion.div
                className="flex text-[14px] font-normal justify-between items-center w-full max-w-1xl sm:items-center  "
                layout
                // transition={{ duration: 10, ease: "linear" }}
              >
                <div className="flex  text-foreground1 justify-between items-center w-full max-w-1xl sm:hidden ">
                  <IconButton
                    icon={<HomeIcon />}
                    action={() => navigation("/")}
                  />
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Context"
                      action={() => handleScroll("Context")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Problem"
                      action={() => handleScroll("ProblemStatement")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Persona"
                      action={() => handleScroll("TargetPersona")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Research"
                      action={() => handleScroll("Research")}
                    />
                  </motion.div>
                  <motion.div className="cursor-pointer ">
                    <ButtonV2
                      label="Ideation"
                      action={() => handleScroll("Ideation")}
                    />
                  </motion.div>
                  <motion.div
                    className="cursor-pointer "
                    onClick={() => handleScroll("VisualDesign")}
                  >
                    <ButtonV2
                      label="Visuals"
                      action={() => handleScroll("VisualDesign")}
                    />
                  </motion.div>
                </div>
                {/* <FloatingNav /> */}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
          }}
          className="bg-background"
        >
          {/* Older design */}

          {/* <FloatingNav /> */}

          <IntroContainer>
            <Sp5 />
            <ColumnContainer>
              <GiantHeading>GE Vernova : Drone Blade Inspection</GiantHeading>
              <Sp1 />
              <Subheading className="text-foreground2">
                Introducing Image Comparison in Drone Blade Inspection
              </Subheading>
              <Sp4 />
              <Divider />
              <Sp4 />
            </ColumnContainer>
            <ColumnContainer>
              <Title> My Role</Title>
              <Sp1 />
              <Body className="text-foreground1">
                As part of GE design team, I was responsible for the design of
                drone image comparison feature. I worked with other designers,
                PMs and engineers to ship the new set of capability in
                inspection tool.
              </Body>
              <Sp2 />

              <Sp4 />
              <Divider />
            </ColumnContainer>
            <Sp4 />
            <div className=" flex flex-row justify-between w-full sm:bg-red-100">
              <div className="w-[100%] flex flex-col gap-[0.5rem] align-right ">
                <Title>Team</Title>
                <div className="flex flex-row  items-start  w-full">
                  <AnimatedTooltip items={people} />
                </div>
              </div>
              <div className="w-[100%]">
                <Title>Timeline</Title>
                <Sp2 />
                <Body style={{ width: "auto" }}>3 Month</Body>
              </div>
              <div className="w-[100%]">
                <Title>Tools</Title>
                <Sp2 />
                <Body style={{ width: "auto" }}>Figma </Body>
                <Body style={{ width: "auto" }}>Figjam </Body>
              </div>
              <div className="w-[100%]">
                <Title>Tasks</Title>
                <Sp2 />
                <Body style={{ width: "auto" }}>Stakeholder Interviews</Body>
                <Body style={{ width: "auto" }}>
                  User Stories (Requirement Gathering)
                </Body>

                <Body style={{ width: "auto" }}>
                  Concept & Layout Designs (Ideation)
                </Body>

                <Body style={{ width: "auto" }}>Wireframing & Visuals</Body>

                <Body style={{ width: "auto" }}>Prototyping </Body>

                <Body style={{ width: "auto" }}>Presenting Stakeholders </Body>
              </div>
            </div>
          </IntroContainer>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
            className="bg-background"
          >
            <PContainer>
              <Sp4 />
              {/* <ColumnContainer>
              <TitleContainer>
                <TitleCircle />
                <Title> Overview </Title>
              </TitleContainer>
              <Sp3 />
              <div>
                <Body>
                  Inspectors require the ability to easily view/compare images
                  from either a current inspection or previous inspections while
                  processing an image from a current inspection. Comparison
                  should be part of the post processing process. Interface
                  should display images side by side for easy comparison.
                </Body>
              </div>
            </ColumnContainer> */}

              {/* New Design ********************* */}

              <ColumnContainer ref={scrollRefs["Context"]}>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> Context (Must Read) </SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading>
                  Would you believe if I say world’s 30% power is being produced
                  by GE ? Yes, its true. While we do not hear about GE a lot in
                  daily life, but it is around us in some form.
                </Heading>
                <Sp6 />
                <Body>
                  GE Renewable provides physical and digital renewables energy
                  solutions. Wind energy is one of their biggest business in the
                  renewable space.
                </Body>
                <Sp0 />
                <Body>
                  Along with Installing the wind solutions.
                  <Highlight>
                    GE also provide maintenance of the assets, so that turbines
                    can keep producing energy.
                  </Highlight>
                </Body>
                <Sp3 />
                <Subtitle>
                  What is blade inspection & image comparison? 🧐
                </Subtitle>
                <Sp1 />
                <Body>
                  Turbine blades are the one of the most crucial component in a
                  turbine and these blades also have subparts like Rotor.
                  <Highlight>
                    Engineers use drones to inspect blades monthly. This
                    process, called blade inspection, involves comparing the
                    current condition of the blades with past images. This
                    comparison is known as image comparison.
                  </Highlight>
                </Body>
              </ColumnContainer>

              <div className="flex justify-between items-center  py-[24px] px-[24px] rounded border-[1px] border-background2 ">
                <div className="flex flex-col justify-between items-center gap-[42px] my-[24px] mx-[32px]">
                  <Turbine2 />
                  <div className="text-center text-foreground2 ">
                    Due to very high length of turbines Inspectors uses drones
                    to capture the videos and images of blades.{" "}
                  </div>
                </div>
                <div className="flex flex-col justify-between items-center gap-[42px] my-[24px] mx-[32px]">
                  <DroneIcon />
                  <div className="text-center text-foreground2  ">
                    These videos logged in to the tool for the inspection
                    process
                  </div>
                </div>
                <div className="flex flex-col justify-between items-center gap-[42px] my-[24px] mx-[32px]">
                  <AnalyticsIcon />
                  <div className="text-center text-foreground2 ">
                    Inspectors compare images from past inspection to latest
                    inspection to identify any problem in blades
                  </div>
                </div>
              </div>

              <ColumnContainer>
                <Subheading>Inspection Workflow </Subheading>
                <Sp1 />
                <Body>
                  Below is end to end inspection workflow which currently take
                  place in Third Party tool (TPA) and in lifespan as well.
                </Body>
                <Sp3 />
                <HeroImage src={CurrentJourney} />
              </ColumnContainer>
              <Sp6 />
              <ColumnContainer ref={scrollRefs["TargetPersona"]}>
                <Sp6 />

                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> Target Persona </SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading>
                  A lot of products are designed for everyone but, This one we
                  designed for specific engineers and renewable customers{" "}
                </Heading>
                <Sp6 />
                <div className="flex justify-between items-center  py-[24px] px-[24px] rounded border-[1px] border-background2 ">
                  <div className="flex flex-col justify-between items-center gap-[48px] my-[24px] mx-[32px]">
                    <EngineerFace />
                    <div className="flex flex-col gap-[16px] ">
                      <div className="text-center text-foreground2 font-[14px] font-bold">
                        GE INSPECTION ENGINEERS
                      </div>
                      <div className="text-center text-foreground2 w-[40vw] ">
                        Inspection engineer are the primary persona for this
                        tool. They will use it for comparing blade images and
                        mark damages as needed
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between items-center gap-[60px] my-[24px] mx-[32px]">
                    <CustomerFace />
                    <div className="flex flex-col gap-[16px] ">
                      <div className="text-center text-foreground2 font-[14px] font-bold">
                        GE CUSTOMERS
                      </div>
                      <div className="text-center text-foreground2  w-[40vw]  ">
                        GE Customers also uses image comparison in order to
                        check the physical condition of the blades. They also
                        notify engineers if they find some malfunction on
                        blades.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <NoteContainer>
                <Title className="text-foreground2">
                  GE Renewable Business
                </Title>
                <List>
                  Incorporating image comparison within Lifespan eliminates
                  dependency on TPA.
                </List>
                <List>
                  IC can be included as a default offering, adding value to the
                  digital solution.
                </List>
                <Sp2 />
                <Title className="text-foreground2">GE Clients/Customers</Title>
                <List>
                  IC accelerates the damage identification process for in-house
                  engineers of GE customers.
                </List>
                <Sp2 />
                <Title className="text-foreground2">
                  GE Inspectors & Engineers
                </Title>
                <List>
                  IC is crucial for making the process faster and more
                  efficient.
                </List>
              </NoteContainer> */}
              </ColumnContainer>
              <Sp6 />

              <ColumnContainer ref={scrollRefs["ProblemStatement"]}>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> Problem </SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading>
                  Even after paying ton of money to an outsider Inspectors were
                  still doing a lot of manual work.
                </Heading>
                <Sp6 />
                <Body>
                  The inspectors currently uses Third Party Apps (TPA) like ziet
                  view for comparison and annotations. The dependency on TPA
                  costs :
                </Body>
                <Sp2 />
                <div className="grid grid-cols-2 grid-rows-2 gap-[60px] flex-row  justify-between w-[max] py-[48px] px-[24px] rounded border-[1px] border-background2">
                  <div className="w-[max]">
                    <Title className="text-foreground1"> Efficiency</Title>
                    <Sp1 />

                    <Body style={{ width: "auto" }}>
                      <span className="font-medium text-foreground1">
                        {" "}
                        Around 60% damages marked by TPA are not accurate.{" "}
                      </span>{" "}
                      Inspectors have to again go through the cycle of marking
                      damages in lifespan
                    </Body>
                  </div>

                  <div className="w-[auto]">
                    <Title className="text-foreground1">Cost</Title>
                    <Sp1 />

                    <Body style={{ width: "100%" }}>
                      <span className="font-medium text-foreground1">
                        Clients and GE have to pay 25% of total cost{" "}
                      </span>{" "}
                      to TPA in order to include Inspection & comparison process
                    </Body>
                  </div>
                  <div className="w-[auto]">
                    <Title className="text-foreground1">
                      {" "}
                      High Resolution Time
                    </Title>
                    <Sp1 />

                    <Body style={{ width: "100%" }}>
                      <span className="font-medium text-foreground1">
                        Inclusion of Image comparison can reduce resolution time
                        by 2X.{" "}
                      </span>{" "}
                      Due to the inefficiency of TPA, it take more time than
                      expected to resolve the issues of turbine blades which
                      reduce the production and increase the downtime of
                      turbine.
                    </Body>
                  </div>
                  <div className="w-[auto]">
                    <Title className="text-foreground1"> Data Loss</Title>
                    <Sp1 />

                    <Body style={{ width: "100%" }}>
                      {" "}
                      When inspectors transfer data from TPA to Lifespan for
                      further process, it breaks and lots of time inspectors
                      have to manually add meta data for each image. Due to data
                      loss this automatic data transfer process becomes tedious.
                    </Body>
                  </div>
                </div>
              </ColumnContainer>
              <ColumnContainer>
                <div className=" flex flex-col h-[100vh] w-[40vw] justify-center items-start">
                  <GiantHeading>
                    So, How might we integrate Blade Image Comparison Process
                    into lifespan (GE legacy application)
                  </GiantHeading>
                  <Sp3 />
                  <Body style={{ width: "100%" }}>
                    But its not as easy as it sound.{" "}
                    <Highlight>
                      {" "}
                      Upon talking to users we got to know, they even have
                      problems with the current tools as well.
                    </Highlight>{" "}
                    So now the real challenge was to build a Comparison tool
                    which is better than the competitors.{" "}
                  </Body>
                </div>
              </ColumnContainer>

              <ColumnContainer>
                <Subtitle>
                  BUT WHY DO GE CARE ABOUT ALL OF THIS ONCE THEY HAVE SOLD THE
                  TURBINES? 🤯
                </Subtitle>
                <Sp1 />
                <Body>
                  <Highlight>
                    GE signs LTSA with clients and In LTSA they promise minimum
                    98% availability of the assets (turbines).{" "}
                  </Highlight>
                  And if turbines availability goes less than 98%, GE will have
                  to pay clients the lost energy amount. In order to keep assets
                  up, resolution process of damages has to fasten and efficient.
                </Body>
                <Sp4 />
                <Body>
                  {/* <Highlight style={{ color: "hsl(var(--nextui-prompt))" }}>
                  UX Team have to integrate the image comparison process in
                  lifespan, so that above problems can be solved
                </Highlight> */}
                </Body>
              </ColumnContainer>

              <Sp6 />

              <ColumnContainer ref={scrollRefs["Research"]}>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> Research </SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading>
                  GE’s Turbine blades can be 107 meter long (about the same
                  length as football field) ,so does the domain. It took us 4-5
                  weeks to get an understanding of how turbine blades inspection
                  works and their problems!!{" "}
                </Heading>
                <Sp6 />
                <Sp6 />

                <Title className="text-foreground1">
                  Problem Identification from business
                </Title>
                <Sp1 />
                <Body>
                  To address the challenges, the UX team followed a non-linear
                  design process. We identified the problem from a business
                  perspective, with{" "}
                  <Highlight>
                    {" "}
                    Scott presenting the need for inspectors to easily view and
                    compare images from current and previous inspections.
                  </Highlight>
                  Extensive discussions with stakeholders, including Scott,
                  helped gather following crucial insights.
                </Body>
                <Sp6 />
                <Title className="text-foreground2">Outcome</Title>
                <NoteContainer>
                  <OutcomeCard
                    outcome={
                      "The inclusion of TPA results in complexity in data management."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      " Scott aims to implement image comparison in Lifespan based on client and executive requests"
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "Concept development will involve user feedback and further questioning."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "The feature rollout is planned in two phases: Minimum Viable Product (MVP) and the final version."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "The complete design process will be followed for the final version, with MVP focused on Phase 1."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "The estimated timeline for feature development is approximately 6 weeks."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "Users require an effective way to select and compare two images based on date/time and location."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "Converting the physical blade into a digital product is a significant challenge, but it will simplify location selection"
                    }
                  />
                </NoteContainer>
              </ColumnContainer>
              <ColumnContainer>
                <Title className="text-foreground1">Some Desk Research</Title>
                <Sp1 />
                <Body>
                  Since the challenge was to put together a concept with broken
                  pieces of puzzle. We really wanted to understand how blade
                  looks like for that we decided to research the blade anatomy.
                </Body>
                <Sp3 />
                <HeroImage src={Skelton} />
                <Sp4 />
                <Body>
                  <Highlight style={{ color: "hsl(var(--nextui-prompt))" }}>
                    Building upon this knowledge, the team formulated a
                    hypothesis and created a concept to present to users. The
                    concept aimed to address the challenges faced by inspectors,
                    enabling them to compare images effectively.
                  </Highlight>
                </Body>
              </ColumnContainer>

              <ColumnContainer>
                <Title className="text-foreground1">
                  Problem Identification from users
                </Title>
                <Sp1 />
                <Body>
                  The team then conducted user interviews to validate the
                  hypothesis and gather additional insights. These interviews
                  revealed key findings:
                </Body>
                <Sp3 />
                <HeroImage src={InitialConcept} />
                <Sp6 />
                <Sp6 />

                <Title className="text-foreground2">Outcome</Title>
                <NoteContainer>
                  <OutcomeCard
                    outcome={
                      "Inspectors currently use Zeit View and occasionally Horizon for image comparison."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Zeit View lacks the ability to annotate the primary image during comparison, which users find highly beneficial."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Users appreciate the location selection and z-index functionality offered by Zeit View's blade illustration."
                    }
                  />

                  <OutcomeCard
                    outcome={`The term "Side" does not adequately convey location; users  suggest using "Angle" instead.`}
                  />

                  <OutcomeCard
                    outcome={
                      "Selecting images based on location should be intuitive, with a clear visualization of damages around the blade."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Navigation within the tool can be confusing, as the location dropdown changes the location of the current image instead of switching the image itself."
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "In the current inspection post process, Users want to filter the images by Damage Source and Category"
                    }
                  />
                  <OutcomeCard
                    outcome={
                      "In inspection list workflow indicators  are similar to asset state badge, they are not very easy to read "
                    }
                  />
                </NoteContainer>
              </ColumnContainer>
              <ColumnContainer>
                <Title className="text-foreground1">Competitive Analysis</Title>
                <Sp1 />
                <Body>
                  To gain further insights and identify essential features, the
                  team conducted a competitive analysis of existing image layout
                  and comparison tools. This analysis informed the selection of
                  features to incorporate into the product.
                </Body>
                <Sp3 />
                <HeroImage src={CA} />
                <Sp6 />
                <Sp6 />

                <Title className="text-foreground2">Outcome</Title>
                <NoteContainer>
                  <OutcomeCard
                    outcome={
                      "Direction of image comparison is left to right in all of the comparison tools."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Meta data of the image should be shown upfront to help users make decision."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Browsing of images should be a tile view which is an existing pattern we noticed in all the comparison tools."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Layout of all the tools is very similar, a 4-5 column grid keeping comparison images in the centre."
                    }
                  />
                </NoteContainer>
              </ColumnContainer>
              <Sp6 />
              <ColumnContainer ref={scrollRefs["Ideation"]}>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader> Ideation </SectionHeader>
                </TitleContainer>
                <Sp2 />

                <Heading>
                  We did almost 20+ layout to get to this layout idea. The
                  biggest challenge was to replicate a blade and show all sides
                  of blade in the tool to make the interaction smoothest.
                </Heading>
                <Sp5 />
                <Title className="text-foreground1">
                  Drafting User Stories & Layout
                </Title>
                <Sp1 />

                <Body>
                  With a comprehensive understanding of user requirements and
                  the insights gathered from the research, We compiled a final
                  list of user stories.{" "}
                  <Highlight>
                    {" "}
                    Primarily we focused on three major asks as follows{" "}
                  </Highlight>
                </Body>
                <Sp2 />
                <List> Ability to Compare Images </List>
                <List> Blade Skeleton (illustration) to select location </List>
                <List> Ability to add annotation on comparison Images </List>

                <Sp6 />
                <HeroImage src={Layout} />
                <Sp2 />
                <HeroImage src={StoryMap} />
              </ColumnContainer>
              <ColumnContainer>
                <Title className="text-foreground1">Concept Wireframes</Title>
                <Sp1 />
                <Body>
                  We transformed insights into tangible wireframes, capturing
                  the essence of the envisioned product. Through multiple
                  iterations and feedback sessions with internal users and PMs,
                  we fine-tuned the wireframes to align with project goals.
                </Body>
                <Sp3 />
                <HeroImage src={Wireframes} />
              </ColumnContainer>
              <Sp6 />
              <ColumnContainer>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader>Validation 1</SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Title className="text-foreground1">
                  User & Business validation of Wireframes: Refining the Vision
                </Title>
                <Sp1 />
                <Body>
                  The initial feedback on the wireframes helped us to set the
                  direction for the further visual design. This also validates
                  that we captured the correct requirement
                </Body>
                <Sp6 />
                <Title className="text-foreground2">Outcome</Title>
                <NoteContainer>
                  <OutcomeCard
                    outcome={
                      "Iteration 3.1 works in all the use cases, and also an scalable option but it requires some improvements in terms of zoning of the layout"
                    }
                  />

                  <OutcomeCard
                    outcome={
                      " Toggle button for image vs blade should be on the right side same as other part of the applications"
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "For MVP we will only develop condensed view of blade control."
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "Improvements of table and post processing will not be part of MVP"
                    }
                  />

                  <OutcomeCard
                    outcome={
                      "The results table requires revamp as it is not communicatin well enough"
                    }
                  />
                </NoteContainer>
              </ColumnContainer>
              <Sp6 />
              <ColumnContainer ref={scrollRefs["VisualDesign"]}>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader>Visual Design</SectionHeader>
                </TitleContainer>
                <Sp2 />
                <Heading>
                  Finally!! We brought ideas to reality after a good feedback
                  and iteration. We knew we were doing it right when one of the
                  user said “WOW, IT WILL BE COOL ” in one of the demo sessions.
                </Heading>

                <Sp4 />
                <Body>
                  With the wireframes validated, the team transitioned to the
                  visual design phase. They crafted stunning visuals to elevate
                  the user experience and enhance the overall aesthetics of the
                  product.
                </Body>
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>Inspection List</Title>
                <Sp1 />
                <Body>
                  Redesigned and introduced new icons in workflow status
                </Body>
                <Sp4 />
                <List>
                  When we interviewed users, they mentioned that the workflow
                  icons resembled the asset state icons, making it difficult to
                  distinguish between them. The previous icons didn't
                  effectively communicate the various stages of each workflow.
                </List>
                <Sp2 />
                <List>
                  The previous icons had accessibility issues, lacking clear
                  indicators of state. Relying solely on text made it difficult
                  for users to easily interpret and understand the correct
                  information.
                </List>
                <Sp6 />
                <HeroImage src={FV1} />

                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>POST PROCESSINg</Title>

                <Sp4 />
                <List>
                  We introduced the blade view in post-processing to simplify
                  blade navigation and enhance user experience.
                </List>
                <Sp2 />
                <List>
                  Based on user feedback, the Blade Serial Number and Side
                  fields have been converted to view-only/manual edit fields.
                  Previously, these were dropdowns, which gave the misleading
                  impression that selecting an option would change the location
                  rather than update the metadata.
                </List>
                <Sp2 />
                <List>
                  We minimized the blade selection real estate and removed the
                  redundant use of the word "blade."{" "}
                </List>
                <Sp2 />
                <List>
                  The video dropdown was removed since it served as metadata
                  rather than a selection option.{" "}
                </List>
                <Sp2 />
                <List>
                  Added filtering options to allow users to sort images by
                  damage source and category.
                </List>
                <Sp6 />
                <HeroImage src={FV2} />

                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>Carousel view of images</Title>

                <Sp4 />
                <List>
                  Improved the carousel view as the current one wasn't scaling
                  well on smaller resolutions.
                </List>
                <Sp2 />
                <List>
                  Larger images in the carousel help users select blades and
                  inspect damage more effectively, as all blade images look
                  similar from a bird’s-eye view.
                </List>

                <Sp6 />
                <HeroImage src={FV3} />
                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>Blade view - A new Addition</Title>

                <Sp4 />
                <List>
                  The blade view was the standout feature of the new set of
                  updates.
                </List>
                <Sp2 />
                <List>
                  It is a parity feature with third-party tools but offers
                  enhanced capabilities. Users strongly advocated for it and
                  were eager to see it implemented.
                </List>
                <Sp2 />
                <List>
                  We positioned the blade horizontally, unlike competitors, and
                  added a switch for all sides.{" "}
                </List>

                <Sp6 />
                <HeroImage src={FV4} />
                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>expanded Blade view</Title>

                <Sp4 />
                <List>A missing feature from our references competitors.</List>
                <Sp2 />
                <List>
                  Our research indicated that users want the ability to view the
                  entire blade for cases of large spread damages.
                </List>
                <Sp2 />
                <List>
                  Each dot on all sides is aligned parallel, allowing users to
                  explore the blade using an expanded view.
                </List>

                <Sp6 />
                <HeroImage src={FV5} />
                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>Image comparison</Title>

                <Sp4 />
                <List>
                  A side-by-side image comparison, a major user request, makes
                  it easier to identify faults and damages through direct
                  comparison.
                </List>
                <Sp2 />
                <List>
                  We also added the option to switch to blade view while
                  comparing, which helps users speed up the process.
                </List>
                <Sp2 />
                <List>
                  A Sync button was added to allow users to synchronize the
                  comparison image (right) with the main image based on the date
                  of inspection.
                </List>

                <Sp6 />
                <HeroImage src={FV6} />
                <Sp5 />
                <Sp5 />
                <Sp5 />
                <Sp5 />

                <Title>view previous annotations</Title>

                <Sp4 />
                <List>
                  To meet the specific needs of the engineer persona, we added
                  the capability to view previous inspections on the comparison
                  image.
                </List>
                <Sp2 />
                <List>
                  The right toolbar allows users to add additional annotations
                  while comparing images.
                </List>

                <Sp6 />
                <HeroImage src={FV7} />
              </ColumnContainer>

              <Sp6 />
              <ColumnContainer>
                <TitleContainer>
                  <TitleCircle />
                  <SectionHeader>Conclusion & Learnings</SectionHeader>
                </TitleContainer>

                <Sp4 />
                <List>
                  Talking to users with draft designs in hand always gives
                  better insights.
                </List>
                <Sp2/>
                <List>
                  Stakeholders mostly talk from the business aspects but user
                  can give the real usability insights.
                </List>
                <Sp2/>
                <List>
                  In the next versions, A 3D blade handle can become a helpful
                  tool in order to select blade axis and locations.
                </List>
                <Sp2/>
                <List>
                  Reading fundamentals helps, There is no shame in becoming a
                  child again and learn to get the basics right.
                </List>
                <Sp2/>
                <List>
                  Elimination over Addition - Very helpful approach to make the
                  UI clean and intuitive.
                </List>
              </ColumnContainer>
              <Sp6 />

              {/* Old Design ---------------------######################++++_______________________*/}
            </PContainer>
          </div>
        </div>
      </PasswordProtectedPage>
    </>
  );
};
