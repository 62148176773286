import AnimatedSVG, { Flask } from "../../Icons/Flask";
import { Instagram } from "../../Icons/Instagram";
import { Linkdin } from "../../Icons/Linkdin";
import { Twitter } from "../../Icons/Twitter";
import {
  ContentWrapperStrip,
  FContainer,
  Strip,
  StripItem,
} from "../../styles/Footer.styled";
import Clock from "../ui/LocalTime";
import { AnimatedLinks } from "./AnimatedLinks";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigation = useNavigate();

  const path = useRef(null);
  let progress = 0;
  let x = 0.5;
  let time = Math.PI / 2;
  let reqId = null;

  useEffect(() => {
    // Only call setPath if path.current is not null
    if (path.current) {
      setPath(progress);
    }
  }, [path.current]); // Add path.current as a dependency

  const setPath = (progress) => {
    // Check if path.current is null before using it
    if (path.current) {
      const width = window.innerWidth * 0.91;
      path.current.setAttributeNS(
        null,
        "d",
        `M0 250 Q${width * x} ${250 + progress}, ${width} 250`
      );
    }
  };

  const lerp = (x, y, a) => x * (1 - a) + y * a;

  const manageMouseEnter = () => {
    if (reqId) {
      cancelAnimationFrame(reqId);
      resetAnimation();
    }
  };

  const manageMouseMove = (e) => {
    const { movementY, clientX } = e;
    const pathBound = path.current.getBoundingClientRect();
    x = (clientX - pathBound.left) / pathBound.width;
    progress += movementY;
    setPath(progress);
  };

  const manageMouseLeave = () => {
    animateOut();
  };

  const animateOut = () => {
    const newProgress = progress * Math.sin(time);
    progress = lerp(progress, 0, 0.025);
    time += 0.2;
    setPath(newProgress);
    if (Math.abs(progress) > 0.75) {
      reqId = requestAnimationFrame(animateOut);
    } else {
      resetAnimation();
    }
  };

  const resetAnimation = () => {
    time = Math.PI / 2;
    progress = 0;
  };

  return (
    <div className="mt-[20vh] pb-[10vh] flex  flex-col align-baseline justify-center sm:pb-[0vh] sm:mt-[10vh]">
      <div className="relative w-[100vw] h-px mb-5 align-center  sm:h-[10vh] ">
        <div
          onMouseEnter={() => {
            manageMouseEnter();
          }}
          onMouseMove={(e) => {
            manageMouseMove(e);
          }}
          onMouseLeave={() => {
            manageMouseLeave();
          }}
          className="relative  z-10 h-10 w-[100vw] top-[-40px] "
        ></div>
        <div className="flex flex-row-inverse">
          <svg className="absolute w-[100vw] h-[500px] top-[-200px] ">
            <path
              ref={path}
              className="stroke-foreground1 text-white stroke-[1px] fill-none "
            ></path>
          </svg>
        </div>
      </div>
      <FContainer>
        <div
          style={{
            fontFamily: "Avenir",

            fontWeight: "bold",
          }}
          className="  flex w-[100%] uppercase items-center text-left text-[50px] text-foreground1  sm:text-[30px] sm:pt-[1rem]"
        >
          Based in India <br /> Online WorldWide <br />
        </div>
        <div className="flex flex-row w-[100%] justify-between mt-[10px] sm:flex-col sm:gap-[4rem] ">
          <Strip>
            <StripItem className="text-foreground1">
              <Clock />
            </StripItem>
            <StripItem> Made with some ❤️ and React</StripItem>
          </Strip>

          <Strip className="text-foreground1 ">
            <StripItem
              onClick={() =>
                window.open("https://www.linkedin.com/in/workwithpunitgupta/")
              }
            >
              Linkdin
            </StripItem>
            <StripItem
              onClick={() => window.open("https://twitter.com/DJ_iner")}
            >
              X(twitter)
            </StripItem>
            {/* <StripItem onClick={()=>(window.open("https://www.instagram.com/unknown_as_punit/"))}>Instagram</StripItem> */}
          </Strip>

          <Strip
            className="text-foreground2"
            // style={{ alignItems: "flex-end" }}
          >
            <AnimatedSVG />
            <StripItem>© 2024 punit gupta</StripItem>
          </Strip>
        </div>
      </FContainer>
    </div>
  );
};
