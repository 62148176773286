import AnimatedFlask from "../Icons/Flask";
import Gears from "../Icons/Gears";
import { NavbarContainer, MenuButton } from "../styles/Navbar.styled";
import { fadeInBottomVarient, fadeInTopVarient } from "../utils/Variants";
import { ButtonV2, ButtonV3 } from "./Buttons";
import { ThemeSwitcher } from "./Buttons";
import { NavMenu } from "./layouts/NavMenu";
import { WarningStrip } from "./layouts/WarningStrip";
import { motion, AnimatePresence, useScroll, useSpring } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Navbar = (props) => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 150,
    damping: 30,
    restDelta: 0.001,
  });
  const navigation = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [sticky, setSticky] = useState(false);
  console.log("props", props);

  // useEffect(() => {
  //   if (window.innerWidth > 720) {
  //     setOpenMenu(true);
  //     const onScroll = () => {
  //       window.scrollY > 100 ? setSticky(true) : setSticky(true);
  //       setOpenMenu
  //         ? window.scrollY > 100
  //           ? setOpenMenu(true)
  //           : setOpenMenu(true)
  //         : setOpenMenu(true);
  //     };

  //     window.addEventListener("scroll", onScroll);

  //     return () => {
  //       window.removeEventListener("scroll", onScroll);
  //     };
  //   } else {
  //     setSticky(true);
  //     setOpenMenu(false);
  //   }
  // }, []);
  return (
    <div>
      <div className="flex flex-col fixed bg-background h-[13%] w-[92vw] bottom-4 z-[100] rounded rounded-[0.25rem] border-1  border-solid border-background2 overflow-hidden  sm:overflow-hidden sm:h-[auto]   sm:flex-col sm:bg-background sm:py-5">
        <AnimatePresence>
          {openMenu && <NavMenu setOpenMenu={setOpenMenu} />}
        </AnimatePresence>

        <div className="hidden sm:flex"></div>
        {/* <motion.div
          style={{ scaleX }}
          className=" flex flex-row justify-between items-center w-[100%] h-[100%] bg-background2 px-[2rem] origin-[0%] top-0 left-0 right-0 absolute z-[-1]  "
        ></motion.div> */}
        <div className=" flex flex-row justify-between w-[100%] h-[100%] items-center px-[4rem] sm:px-[1rem]  sm:flex-row sm:h-[30px] ">
          <div className="hidden sm:flex">
          {openMenu ? (
            <ButtonV2 label={"Menu"} action={() => setOpenMenu(false)} />
          ) : (
            <ButtonV2 label={"Menu"} action={() => setOpenMenu(true)} />
          )}
          </div>
          <div style={{ cursor: "pointer" }} className="sm:hidden">
            <ButtonV2 action={() => navigation("/")} label={"HOME"} />
          </div>

          <div className="flex flex-row gap-10 items-center sm:hidden">
            {/* <ThemeSwitcher /> */}
            <ButtonV2
              label={"ABOUT ME"}
              action={() => navigation("/AboutMe")}
            />
            <ButtonV2
              label={"RESUME"}
              action={() => window.open("resume.pdf")}
            />
            <div className="flex ">
              <ButtonV2 label={"LAB"} action={() => navigation("/MyLibrary")} />
            </div>
          </div>
          <div className="flex">
            <ButtonV3
              label={"CONTACT"}
              action={() =>
                (window.location = "mailto:workwithpunitgupta@gmail.com")
              }
            />
          </div>
        </div>
      </div>
      {/* <WarningStrip /> */}
    </div>
  );
};
