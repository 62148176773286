import styled from "styled-components";

export const MainBody = styled.div`
  /* background-color: black; */

  @media (max-width: 720px) {
    width: 100vw;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: blue;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const PaddingContainer = styled.div`
  padding-top: ${({ top }) => top};
  padding-bottom: ${({ bottom }) => bottom};
  /* background-color: black; */
  /* margin-right: 5rem; */

  @media (max-width: 720px) {
    padding-top: ${({ responsiveTop }) => responsiveTop};
    padding-bottom: ${({ responsiveBottom }) => responsiveBottom};
    padding-left: ${({ reponsiveLeft }) => reponsiveLeft};
    padding-right: ${({ reponsiveRight }) => reponsiveRight};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;
  background-color: blue;

  & > div {
    flex: ${({ fullWidthChild }) => fullWidthChild && 1};
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
  }
`;

export const Heading = styled(PaddingContainer)`
  color: ${({ theme }) => theme.colors.white};
  text-align: ${({ align }) => align};
  font-size: ${({ size }) => {
    switch (size) {
      case "h1":
        return "4.5 rem";

      case "h2":
        return "3rem";

      case "h3":
        return "2rem";

      case "h4":
        return "1.2rem";

      default:
        return;
    }
  }};

  @media (max-width: 720px) {
    font-size: ${({ size }) => {
      switch (size) {
        case "h1":
          return "2.5 rem";

        case "h2":
          return "2rem";

        case "h3":
          return "1.5rem";

        case "h4":
          return "1rem";

        default:
          return;
      }
    }};
  }
`;

export const BlueText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ParaText = styled(PaddingContainer)`
  color: ${({ theme }) => theme.colors.para_text_color};
  
`;

export const IconContainer = styled.div`
  font-size: ${({ size }) => size};
  cursor: pointer;
  color: ${({ color, theme }) => {
    switch (color) {
      case "white":
        return theme.colors.white;

      case "blue":
        return theme.colors.secondary;
      default:
        return;
    }
  }};
`;

export const Button = styled.a`
  display: inline-block;
  width: max-content;
  padding-top: 2rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: medium;
  font-weight: bold;
  cursor: pointer;
  text-size-adjust: bold;
  transition: all 0.3s ease;
  text-decoration: underline;

  &:hover {
    font-size: large;
  }
`;

export const FadeImage = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};
  z-index: 0;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const FocusArea = styled.div`
  height: auto;
  width: auto;
  background-color: #1b1a1a;
  padding: 1rem;
  border-radius: 8px;
  color: white;
`;

// ${({ responsiveFlex }) => (responsiveFlex ? "flex" : "block")};
