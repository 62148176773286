import React, { useState, useEffect } from "react";

const Clock = () => {
  const [date, setDate] = useState(new Date().toLocaleTimeString("en-IN"));

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    setDate(new Date().toLocaleTimeString("en-IN"));
  };

  return (
    <div
      className="text-foreground1 text-[14px] sm:text-[16px]"
      style={{ fontFamily: "avenir", marginTop: "1px" }}
    >
      {" "}
      Delhi, India {date}
    </div>
  );
};

export default Clock;
