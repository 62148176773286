import {
  ProjectName,
  LeftContainer,
  RightContainer,
  ParentContainer,
  ProjectDescription,
  ProjectImage,
  Role,
  Head,
  ListWrapper,
  BottomContainer,
} from "../../styles/CaseStudy.styled";
import {
  Sp0,
  Sp1,
  Sp2,
  Sp3,
  Sp4,
  Sp5,
} from "../../styles/CaseStudyDetail.styled";
import { TitleCircle } from "../../styles/CaseStudyDetail.styled";
import { Case } from "../../utils/Data";
import ButtonWithGradientAndAnimation, {
  ButtonV2,
  ButtonV3,
  IconButton,
  MagnetButton,
} from "../Buttons";
import Badge from "../ui/Badge";
import { WobbleCard } from "../ui/WobbleCard";
import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

// import { Pill } from "../Buttons";

export const CaseStudyList = () => {
  const navigation = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <div className="bg-background">
      <div className="flex  items-center gap-2 h-[2rem] ">
        <TitleCircle />
        <h1
          className=" horror-dark font-bold  uppercase   text-left text-title "
          style={{
            fontFamily: "Avenir",

            fontSize: "14px",
          }}
        >
          Selected Work
          <br />
        </h1>
      </div>
      {/* <Head className="text-foreground1">
        Selected Work
        {/* <UseAnimations
          animation={scrollDown}
          size={32}
          strokeColor="#fff"
          speed={2}
        />{" "} */}
      {/* </Head> */}

      <ListWrapper>
        {Case.map((item, index) => (
          <div className="rounded-[0.25rem] border-background2 border-[1px] overflow-hidden ">
            <ProjectImage
              className="   -bottom-10 object-scale-down "
              src={item.img}
            ></ProjectImage>

            <BottomContainer>
              <ProjectName className="text-foreground1">
                {item.name}
              </ProjectName>
              <Sp1 />
              <Role className="text-foreground2"> {item.role} </Role>
              <Sp2 />
              {/* <ProjectDescription className="text-foreground1">
                {" "}
                {item.desc}
              </ProjectDescription> */}
              <Sp2 />
              <div>
                <IconButton
                  icon={item.statusIcon}
                  action={() => navigation(item.path)}
                />
              </div>

              {/* <Divider className="dark" /> */}
            </BottomContainer>
          </div>
        ))}
      </ListWrapper>
    </div>
  );
};
