import {
  Body,
  Sp0,
  Sp1,
  Sp2,
  Title,
} from "../../styles/CaseStudyDetail.styled";
import React from "react";

export const ColorSwatch = ({ name, hex, rgb,text }) => {
  return (
    <div className=" flex w-auto items-center gap-4">
      <div
        className="rounded-full"
        style={{ width: "90px", height: "90px", backgroundColor: `${hex}` }}
      ></div>

      <div className="flex-column">
        <div  style={{ width: "auto", color:`${text}`, }} className="text-[16px] font-medium">
          {name}
        </div>
        <Sp2 />
        <div className="flex-column">
          <div style={{color:`${text}`, fontFamily:"PPNeueMontrealMono",opacity:"0.5"}} className="text=[14px]"> {hex}</div>
          <Sp0 />
          <div style={{color:`${text}`,fontFamily:"PPNeueMontrealMono", opacity:"0.5"}} className="text=[14px]">{rgb}</div>
        </div>
      </div>
    </div>
  );
};
