import React, { useEffect } from "react";

export const Example = () => {
  return (
    <div className="grid  place-content-center ">
      <BubbleText />
    </div>
  );
};

export const BubbleText = ({ text }) => {
  useEffect(() => {
    const spans = document.querySelectorAll(".hover-text span");

    spans.forEach((span) => {
      span.addEventListener("mouseenter", function () {
        this.style.fontWeight = "100";
        this.style.color = "#3cda64";

        const leftNeighbor = this.previousElementSibling;
        const rightNeighbor = this.nextElementSibling;

        if (leftNeighbor) {
          leftNeighbor.style.fontWeight = "300";
          leftNeighbor.style.color = "skyblue";
        }
        if (rightNeighbor) {
          rightNeighbor.style.fontWeight = "700";
          rightNeighbor.style.color = "yellow";
        }
      });

      span.addEventListener("mouseleave", function () {
        this.style.fontWeight = "900";
        this.style.color = "hsl(var(--nextui-foreground1))";

        const leftNeighbor = this.previousElementSibling;
        const rightNeighbor = this.nextElementSibling;

        if (leftNeighbor) {
          leftNeighbor.style.fontWeight = "900";
          leftNeighbor.style.color = "hsl(var(--nextui-foreground1))";
        }

        if (rightNeighbor) {
          rightNeighbor.style.fontWeight = "900";
          rightNeighbor.style.color = "hsl(var(--nextui-foreground1))";
        }
      });
    });
  }, []);
  return (
    <h2 className="hover-text text-left text-5xl font-[900] w-[50vw] uppercase leading-[1.4em] text-foreground1 sm:text-3xl sm:w-[90vw] sm:leading-[1.6em] ">
      <Text>{text}</Text>
    </h2>
  );
};

const Text = ({ children }) => {
  const text = children.toString();
  return (
    <>
      {text.split("").map((child, idx) => (
        <span
          style={{
            fontFamily: "Inter",
            transition: "0.35s font-weight, 0.35s color",
          }}
          key={idx}
        >
          {child}
        </span>
      ))}
    </>
  );
};

export default Example;
