import styled from "styled-components";

export const FContainer = styled.div`
  width: 100%;
  height: 60vh;
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: flex-end;

  padding-top: 3rem;
  padding-bottom: 10rem;

  @media (max-width: 720px) {
    padding-top: 2rem;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: none;
    gap: 5rem;
  
  }
`;

export const Strip = styled.div`
  width: auto;
  height: 100%;
  /* background-color: #0d0d0d; */
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 720px) {
    display: flex;
    align-items: start;
    flex-direction: column-reverse;
    gap: 1rem;
    height: 50%;
  }
`;

export const StripItem = styled.div`
  /* color: var(foreground1); */
  /* font-weight: normal; */
  display: flex;
  align-items: center;
  width: 100%;
  font-family: avenir;
  /* justify-content: center; */
  font-size: 14px;
  text-transform: uppercase;

  cursor: pointer;

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const ContentWrapperStrip = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 720px) {
    display: flex;
    align-items: center;
  }
`;
