import React from 'react'

export const TrandscendIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 0H12.6486V11.3514H24V0Z" fill="#878787" />
      <path d="M11.3514 0H0V11.3514H11.3514V0Z" fill="#9A9A9A" />
      <path d="M24 12.6486H12.6486V24H24V12.6486Z" fill="#D1D1D1" />
      <path
        d="M0 12.6486V15.5676L6.32433 15.4865L0 21.8919V23.9189H2.02703L8.43244 17.5946V23.9189H11.3514V12.6486H0Z"
        fill="#FCFCFC"
      />
    </svg>
  );
}
