import { Airplane } from "../../Icons/Airplane";
import AOO from "../../assets/P2/AOO.png";
// Buttons
// import { HomeButton } from "../Buttons";

//Modal

// Images

import CoverImage from "../../assets/P2/AirTravel Coverimage.png";
import CA from "../../assets/P2/CA.png";
import CAIntro from "../../assets/P2/CAIntro.png";
import Chat from "../../assets/P2/Chat.png";
import Desk from "../../assets/P2/Desk.png";
import InterviewQuestions from "../../assets/P2/Interview Questions.png";
import MMJam from "../../assets/P2/Mind Mapping.png";
import MMSketch from "../../assets/P2/MindMappingSketch.png";
import Kamesh from "../../assets/P2/Persona.png";
import USP1 from "../../assets/P2/USP 1.png";
import USP2 from "../../assets/P2/USP 2.png";
import USP3 from "../../assets/P2/USP 3.png";
import UnderstandingMap from "../../assets/P2/UnderstandingMap.png";
import {
  HContainer,
  Subheading,
  Heading,
  Body,
  Title,
  StickyContainer,
  MContainer,
  Highlight,
  Subtitle,
  IntroContainer,
  PContainer,
  CContainer,
  ListContainer,
  Sp2,
  Sp4,
  ColumnContainer,
  Sp5,
  Sp1,
  GiantHeading,
  RowContainer,
} from "../../styles/CaseStudyDetail.styled";
import { HeroImage } from "../../styles/CaseStudyDetail.styled";
import { FocusArea } from "../../styles/Global.styled";
import { Divider } from "@nextui-org/react";
import { React } from "react";

export const AirTravelResearchC2 = () => {
  return (
    <div
      style={{
        alignItems: "center",
        width: "100vw",
      }}
      className="bg-background"
    >
      {/* <HomeButton /> */}

      <IntroContainer>
        <HeroImage src={CoverImage} style={{ height: "auto", width: "80vw" }} />
      </IntroContainer>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "60px",
        }}
        className="bg-background"
      >
        <IntroContainer>
          <Sp5 />
          <ColumnContainer>
            <GiantHeading>
              {" "}
              Improvement Opportunities In Air Travel - Research & Insight
              Document
            </GiantHeading>
            <Sp1 />
            <Subheading className="text-foreground2">
              A training research project, where I explored ways, how traveling
              by air can be improved
            </Subheading>
            <Sp4 />
            <Divider />
            <Sp4 />
          </ColumnContainer>
          <ColumnContainer>
            <Body className="text-foreground1">
              The goal of this research was to understand the continuing
              experiences that the present air travel industry offers. In our
              investigation, we looked for other ways to enhance the air travel
              experience beyond only purchasing tickets and obtaining visa
              assistance.
            </Body>
            <Sp2 />

            <Sp4 />
            <Divider />
          </ColumnContainer>

          <Sp4 />
          <div className=" flex flex-row justify-between w-full sm:bg-red-100">
            <div className="w-[100%] flex flex-col gap-[0.5rem] align-right ">
              <Title>Team</Title>
              <div className="flex flex-row  items-start  w-full">
                {/* <AnimatedTooltip items={people} /> */}
              </div>
            </div>
            <div className="w-[100%]">
              <Title>Timeline</Title>
              <Sp2 />
              <Body style={{ width: "auto" }}>1 Month</Body>
            </div>
            <div className="w-[100%]">
              <Title>Tools</Title>
              <Sp2 />
              <Body style={{ width: "auto" }}>Figma </Body>
              <Body style={{ width: "auto" }}>Figjam </Body>
            </div>
            <div className="w-[100%]">
              <Title>Tasks</Title>
              <Sp2 />

              <Body style={{ width: "auto" }}>UX Research</Body>

              <Body style={{ width: "auto" }}>
                Concept & Layout Designs (Ideation)
              </Body>

              <Body style={{ width: "auto" }}>Presenting Stakeholders </Body>
            </div>
          </div>
        </IntroContainer>
        <PContainer>
          {/* Project Introduction starts here */}

          <CContainer>
            <div>
              <Title>Understanding The Air Travel</Title>
              <Body>
                Only 4% of the Indian population flies. It has never been easier
                for traveller to fly by plane though it saves a lot of time. So
                before jumping to any external research method we looked at idle
                thought process about traveling by air of a normal person.
              </Body>
            </div>
            <HeroImage src={UnderstandingMap}></HeroImage>
          </CContainer>

          <CContainer>
            <ColumnContainer>
              <div>
                <Title> Mind Mapping </Title>
                <Body>
                  To materialise all the concepts, thoughts of air travel system
                  we initialise our research with mind mapping. Representing all
                  the ideas and concepts on a single table helps us to get deep
                  understanding of each phase of air travel.
                </Body>
              </div>
              {/* <HeroImage src={MMSketch} style={{ width: "20vw" }}></HeroImage> */}
            </ColumnContainer>
            <HeroImage src={MMJam}></HeroImage>
          </CContainer>
          <CContainer>
            <ColumnContainer>
              <div>
                <Title> Area Of Opportunity </Title>
                <Body>
                  Finding the opportunities is first step to improve any
                  customer experience. To achieve business goals it is needy to
                  having right opportunities. Below is a chart which shows where
                  we can make use of opportunities throughout air travel journey
                </Body>
              </div>
            </ColumnContainer>
            <HeroImage src={AOO}></HeroImage>
          </CContainer>
          <CContainer>
            <ColumnContainer>
              <div>
                <Title>Competetive Analyses</Title>
                <Body>
                  Based on below parameters we did a thorough Competetive
                  Analysis
                </Body>
              </div>
            </ColumnContainer>
            <RowContainer>
            <ColumnContainer>
              <ListContainer>
                <div style={{ display: "flex" }}>
                  <Airplane />
                  <Body style={{ paddingLeft: "1rem", color: "white" }}>
                    Information Architecture
                  </Body>
                </div>
                <div style={{ display: "flex" }}>
                  <Airplane />
                  <Body style={{ paddingLeft: "1rem", color: "white" }}>
                    Color Scheme
                  </Body>
                </div>
                <div style={{ display: "flex" }}>
                  <Airplane />
                  <Body style={{ paddingLeft: "1rem", color: "white" }}>
                    Hero Seciton
                  </Body>
                </div>
                <div style={{ display: "flex" }}>
                  <Airplane />
                  <Body style={{ paddingLeft: "1rem", color: "white" }}>
                    Icons & Illustrations
                  </Body>
                </div>
                <div style={{ display: "flex" }}>
                  <Airplane />
                  <Body style={{ paddingLeft: "1rem", color: "white" }}>
                    Customer Support
                  </Body>
                </div>
              </ListContainer>
            </ColumnContainer>
            
            <HeroImage src={CAIntro} style={{ width: "600px" }}></HeroImage>
            </RowContainer>

            <HeroImage src={CA}></HeroImage>
          </CContainer>
          <div>
            <CContainer>
              <ListContainer>
                <Title>User Interviews</Title>
                <Body>
                  <Highlight>
                    Group 1: First time Air traveller - Pioneer Traveler
                  </Highlight>
                  <br />
                  The prime-focused user group is travellers who are going to
                  travel for the first time by air.
                </Body>

                <Body>
                  <Highlight> Group 2: Frequenty Air Traveler</Highlight>
                  <br />
                  Secondary target users are regular air travellers.
                </Body>
              </ListContainer>
            </CContainer>
            <MContainer>
              <div>
                <Body>
                  <FocusArea>
                    I interviewed 4 people in total for the research. 2 people
                    were experience travellers, 1 was pioneer traveller and I
                    also interviewed a subject matter expert{" "}
                  </FocusArea>
                </Body>
                <div style={{ marginTop: "1rem" }}>
                  {/* <Subtitle
                    onClick={onOpen}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Show Interview Questionare
                  </Subtitle>

                  <div className="w-100 red">
                    <Modal
                      isOpen={isOpen}
                      onOpenChange={onOpenChange}
                      className="dark text-foreground bg-background"
                      size="full"
                    >
                      <ModalContent>
                        {(onclose) => (
                          <>
                            <ModalHeader className="flex flex-col gap-1 dark">
                              Interview Questions & Objectives{" "}
                            </ModalHeader>
                            <ModalBody>
                              <HeroImage src={InterviewQuestions} />{" "}
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="default"
                                variant="light"
                                onClick={onclose}
                              >
                                {" "}
                                Close
                              </Button>
                            </ModalFooter>
                          </>
                        )}
                      </ModalContent>
                    </Modal>
                  </div> */}
                </div>
              </div>
              <HeroImage src={Chat} />
            </MContainer>

            <MContainer>
              <HeroImage src={Kamesh} />
            </MContainer>
          </div>
          <MContainer>
            <div style={{ width: "100%" }}>
              <Title>User Interviews Takeaways</Title>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <div style={{ display: "grid", gap: "2rem" }}>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      Passengers find hard to navigate while walk inside the
                      airport. They can ask help from airport staff but a lot
                      passenger feel hesitate to ask.
                    </Body>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      Poor structure of pick up points at large airports
                      confuses passengers while getting out from the airport. It
                      is very effortful to find receivers at outside
                    </Body>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      Luggage information in not provided over the tickets.
                      Traveler have to look into internet before journey.
                    </Body>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      There should be some special arrangements for disabled and
                      senior citizens. It could be separate que, separate staff
                      etc.
                    </Body>
                  </div>
                </div>
                <div style={{ display: "grid" }}>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      Cash back from the booking is only good if it is usable
                      for other purposes .
                    </Body>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      It should be mentioned somewhere what precautions to take
                      before onboarding. Pioneer travellers should know what
                      kind of vibrations and health experiences they are going
                      to face.
                    </Body>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Airplane />
                    <Body style={{ paddingLeft: "1rem", color: "white" }}>
                      Artefact which are not allowed through the journey need to
                      be mentioned.
                    </Body>
                  </div>
                </div>
              </div>
            </div>
          </MContainer>

          <MContainer>
            <div>
              <Title>Desk Research</Title>
              <Body>
                To add more insights to the research and to finding the patterns
                a document research is being done by us, It helps us to get data
                from already existing researches and articles.
              </Body>
            </div>
          </MContainer>

          <MContainer>
            <HeroImage src={Desk} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Subtitle>
                Sources
                <br />
                timesofindia.indiatimes.com <br />
                www.newdelhiairport.in/passenger-guide/first-time-flyer
                <br />
                cbic.gov.in/resources//htdocs-cbec/press-release/pex-satisfn-survey-csi-mumbai.
              </Subtitle>
            </div>
          </MContainer>
          <MContainer>
            <div style={{ display: "grid", gap: "2rem" }}>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  A IXIGO study revealed that Passengers are sensitive to the
                  time they spend on the entire Customs Clearance Process
                </Body>
              </div>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  The research team has submitted recommendations that would
                  lead to reduction in process time, which in turn would lead to
                  raising satisfaction levels of Passengers.
                </Body>
              </div>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  There is no pre travel self calculation / assessment and
                  payment of customs clearance.
                </Body>
              </div>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  Long time custom clearance frustrates the travellers as they
                  have to wait a long. It becomes big pain when travellers have
                  continues scheduled flights.
                </Body>
              </div>
            </div>
            <div style={{ display: "grid", gap: "2rem" }}>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  In 2018 DGCA chief Arun Kumar has directed that Airports,
                  Indian carries and foreign airlines operating to INDIA must
                  facilitate all passengers, especially senior citizens,
                  expectant mothers, passengers with disability and first time
                  travellers.
                </Body>
              </div>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  Airline/airport shall ensure provision of automated buggies
                  free of charge for all senior citizens, expectant mothers and
                  disabled passengers in the terminal.
                </Body>
              </div>
              <div style={{ display: "flex" }}>
                <Airplane />
                <Body style={{ paddingLeft: "1rem", color: "white" }}>
                  New delhi airport website added a self explanatory flyer for
                  the first time air traveler. It was also recommended to put a
                  helpful information to every portal.
                </Body>
              </div>
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Title>Key Value Propositions</Title>
              <Body>
                These additional new features that can enhance the user
                experience as well as they can add value to the business module.
              </Body>
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Body
                style={{ width: "100%", paddingBottom: "2rem", color: "white" }}
              >
                Dedicated guide for first time air travellers. We can get the
                data from the database that if someone is booking a air facility
                for the first time or not. Also We can ask from the users about
                their air travel status at the time of booking tickets..
              </Body>
              <HeroImage src={USP1} />
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Body
                style={{ width: "100%", paddingBottom: "2rem", color: "white" }}
              >
                For international travelers, We can suggest them the required
                time gap between two flights according to the customs clearance.
                Also we can suggest them some official ways to clear the custom
                checking on time and without any problem.
                <br />
                Quick cards can help the user to get the idea about all the pre
                travel and post travel Experiences.
              </Body>

              <HeroImage src={USP2} />
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Body
                style={{ width: "100%", paddingBottom: "2rem", color: "white" }}
              >
                <li>
                  We can provide the average timing about the flight along with
                  the other details. It would help the traveler to make decision
                  before stepping out to travel.
                </li>
                <li>
                  Providing them cab facility or pre booking cab facility can be
                  a good addition to enhance the travel experience. It is more
                  about to collect and provide all the required facilities on a
                  single platform.
                </li>
              </Body>
              <HeroImage src={USP3} />
            </div>
          </MContainer>
        </PContainer>
      </div>
    </div>
  );
};
