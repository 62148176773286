import { React } from "react";

import {
  HContainer,
  Heading,
  HeroImage,
  Body,
  Highlight,
  Subtitle,
  Title,
  MContainer,
  StickyContainer,
  IntroContainer,
  Subheading,
  PContainer,
  CContainer,
  ListContainer,
} from "../../styles/CaseStudyDetail.styled";

import { motion } from "framer-motion";

// import { HomeButton } from "../Buttons";

import CoverImage from "../../assets/P3 Covid/CoverImage.png";
import StepLadder from "../../assets/P3 Covid/StepLadder.png";
import UserStories from "../../assets/P3 Covid/User Stories.png";
import MOSCOW from "../../assets/P3 Covid/MOSCOW.png";
import IA from "../../assets/P3 Covid/IA.png";
import { FocusArea } from "../../styles/Global.styled";

export const CovidCare = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        alignItems: "center",
        width: "100vw",
      }}
    >
      {/* <HomeButton /> */}

      <IntroContainer>
        <HContainer>
          <Heading>
            CovidCare - A Reatime Dashboard for Covid Tests
          </Heading>
          <Subheading>Psssion Project</Subheading>
        </HContainer>

        <HeroImage src={CoverImage} style={{ height: "auto", width: "80vw" }} />
      </IntroContainer>

      <div
        style={{
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <PContainer>
          <MContainer>
            <div>
              <Title>Project Overview</Title>
              <Body>
                The number of new cases were increasing day by day around the
                world. The idea was to make a dashboard for the Covid testing
                facilities that would assist the COVID test centers in managing
                exams on a weekly or daily basis.
              </Body>
            </div>
            <CContainer>
              <div>
                <Subtitle>MY ROLE</Subtitle>
                <Body>
                  <Highlight>UI designer</Highlight>
                </Body>
              </div>
              <div>
                <Subtitle>TEAM</Subtitle>
                <Body>
                  <Highlight>
                    Punit Gupta <br /> Ankit Ranka
                  </Highlight>
                </Body>
              </div>
            </CContainer>
          </MContainer>
          <div>
            <CContainer>
              <div>
                <Title>Understanding The Problem</Title>
                <Body>
                  Around 1200 individuals tested in India on March 20th 2020,
                  and the number gradually increased from there. It was
                  difficult to manage the tests for the testing places. We chose
                  to address the issue of test management.
                </Body>
              </div>
              <FocusArea>
                The project was more UI focused, keeping that in mind we did
                some secondary research and on the basis on that made
                assumptions
              </FocusArea>
            </CContainer>
          </div>
          <MContainer>
            <div>
              <Title> Indentifying users </Title>
              <Body>User Persona information comes here</Body>
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Title> Understanding Journey </Title>
              <Body>Journey of covid test comes here </Body>
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Body>
                <li>1. Needed data for the process </li>
                <li>
                  2. Thinking in all perspectives (not only from manager point
                  of view, but also healthcare workers and user/patient)
                </li>
                <li>3. Identifying/Analysing the steps/Process.</li>
                <li>4. Connect the flow to all the 3 different users.</li>
                <li> 5. Defining the each user flow(structure)</li>
                <li> 6. Writing down the key points(major).</li>
              </Body>
            </div>
            <HeroImage style={{ width: "600px" }} src={StepLadder}></HeroImage>
          </MContainer>
          <div>
            <Title> User Stories </Title>
            <MContainer>
              <HeroImage src={UserStories} />
            </MContainer>
            <MContainer>
              <div>
                <Title> MoSCoW </Title>
                <HeroImage src={MOSCOW} />
              </div>
            </MContainer>
          </div>
          <MContainer>
            <div>
              <Title> Information Architecture </Title>
              <HeroImage src={IA} />
            </div>
          </MContainer>
          <MContainer>
            <div>
              <Title> Unique Selling Points </Title>
              <div>
                <ListContainer>
                  <CContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading>Bulk Assign</Subheading>
                        <Body>
                          Manager can multi Assign the patients to a single
                          worker with multi selection feature in the table.
                        </Body>
                      </div>
                    </MContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading> Auto Update Data Table</Subheading>
                        <Body>
                          New patient requests and sample collected status is
                          updated automatically with quick toast message
                          notifications.
                        </Body>
                      </div>
                    </MContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading>Map View Tracking</Subheading>
                        <Body>
                          Manager has an option to Track the workers based on
                          the GPS also. Can monitor and track them area wise and
                          sample collection data.
                        </Body>
                      </div>
                    </MContainer>
                  </CContainer>
                  <CContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading>Stats filled Dashboard</Subheading>
                        <Body>
                          Manager can be easily access the monitoring and
                          tracking of the users with the help of a single screen
                          dashboard.
                        </Body>
                      </div>
                    </MContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading>One click Assign</Subheading>
                        <Body>
                          In any stage, there is option to Assign the healthcare
                          workers to the patients instantly as per the free time
                          and convenience of the patient and worker.
                        </Body>
                      </div>
                    </MContainer>
                    <MContainer>
                      <div style={{ width: "100%" }}>
                        <Subheading>Chat option</Subheading>
                        <Body>
                          Chat and message options for the manager to
                          communicate directly to the worker.
                        </Body>
                      </div>
                    </MContainer>
                  </CContainer>
                </ListContainer>
              </div>
            </div>
          </MContainer>
        </PContainer>
      </div>
    </div>
  );
};
