import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";

const CustomerFace = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const updateWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const calculateEyePosition = (eyeCenterX, eyeCenterY) => {
    const deltaX = mousePosition.x - eyeCenterX;
    const deltaY = mousePosition.y - eyeCenterY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const maxDistance = 5; // max distance the pupil can move within the eye

    if (distance < maxDistance) {
      return { x: deltaX, y: deltaY };
    } else {
      const ratio = maxDistance / distance;
      return { x: deltaX * ratio, y: deltaY * ratio };
    }
  };

  const leftEyeCenter = {
    x: windowSize.width / 2 - 50,
    y: windowSize.height / 2 - 50,
  };
  const rightEyeCenter = {
    x: windowSize.width / 2 + 50,
    y: windowSize.height / 2 - 50,
  };

  const eyePosition = calculateEyePosition(
    windowSize.width / 2,
    windowSize.height / 2
  );

  return (
    <svg
      width="auto"
      height="100"
      viewBox="0 0 237 311"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="85"
        y="134"
        width="68.2139"
        height="128.131"
        rx="13.7769"
        fill="white"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M86.896 216.634V175.678H155.5C150.333 196.68 119.523 217.768 86.896 216.634Z"
        fill="#7E7E7E"
        fill-opacity="0.45"
      />
      <path
        d="M79 130.297C79 141.986 68.4499 151.797 55 151.797C41.5501 151.797 31 141.986 31 130.297C31 118.609 41.5501 108.797 55 108.797C68.4499 108.797 79 118.609 79 130.297Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M162 130.297C162 141.986 172.55 151.797 186 151.797C199.45 151.797 210 141.986 210 130.297C210 118.609 199.45 108.797 186 108.797C172.55 108.797 162 118.609 162 130.297Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
      />
      <g clip-path="url(#clip0_202_391)">
        <path
          d="M187 126.129C187 146.469 181.073 161.968 170.053 172.397C159.016 182.842 142.583 188.475 121.03 188.475C99.4768 188.475 82.7676 182.842 71.4607 172.382C60.1775 161.943 54 146.445 54 126.129C54 105.77 60.2108 84.5235 71.7293 68.4178C83.2233 52.3464 99.9224 41.4753 121.03 41.4753C142.131 41.4753 158.56 52.3387 169.788 68.4003C181.045 84.5013 187 105.751 187 126.129Z"
          fill="white"
          stroke="#323232"
          stroke-width="4"
          stroke-linecap="round"
        />
        <path
          d="M120.842 124.475C118.975 132.863 118 137.246 118 145.973C120.501 147.527 122.892 147.916 127 147.872"
          stroke="#323232"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M89 131.5C91.9487 131.5 94.5845 129.945 96.4674 127.497C98.3499 125.05 99.5 121.691 99.5 118C99.5 114.309 98.3499 110.95 96.4674 108.503C94.5845 106.055 91.9487 104.5 89 104.5C86.0513 104.5 83.4155 106.055 81.5326 108.503C79.6501 110.95 78.5 114.309 78.5 118C78.5 121.691 79.6501 125.05 81.5326 127.497C83.4155 129.945 86.0513 131.5 89 131.5Z"
          fill="#323232"
          stroke="#323232"
          stroke-linecap="round"
        />
        <motion.circle
          animate={{ x: eyePosition.x, y: eyePosition.y }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
          cx="89.6162"
          cy="119"
          r="4.5"
          fill="#fff"
          stroke="#323232"
        />
        <path
          d="M161 131.5C163.949 131.5 166.584 129.945 168.467 127.497C170.35 125.05 171.5 121.691 171.5 118C171.5 114.309 170.35 110.95 168.467 108.503C166.584 106.055 163.949 104.5 161 104.5C158.051 104.5 155.416 106.055 153.533 108.503C151.65 110.95 150.5 114.309 150.5 118C150.5 121.691 151.65 125.05 153.533 127.497C155.416 129.945 158.051 131.5 161 131.5Z"
          fill="#323232"
          stroke="#323232"
          stroke-linecap="round"
        />
        <motion.circle
          animate={{ x: eyePosition.x, y: eyePosition.y }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
          cx="160.616"
          cy="119"
          r="4"
          fill="#fff"
        />
        <path
          d="M112 159.475C115 163.142 122.8 168.275 130 159.475"
          stroke="#323232"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g filter="url(#filter0_f_202_391)">
          <ellipse cx="83.5" cy="156.5" rx="9.5" ry="5.5" fill="#FC9D80" />
        </g>
        <g filter="url(#filter1_f_202_391)">
          <ellipse cx="162.5" cy="156.5" rx="9.5" ry="5.5" fill="#FC9D80" />
        </g>
      </g>
      <path
        d="M40.5001 68.7973C38.8598 46.724 45.0001 35.2973 63.5 30.7973C63.5 30.7973 118.5 -23.7026 167 12.2974C215.5 48.2973 185.5 109.5 185.5 109.5C174 94 168.966 90.8931 170 71.2973C128.157 83.8709 110.716 80.1466 87.5 54.2973C84.4808 70.9869 81.0001 77 69.0001 84C71.1473 96.313 58.5001 107 55.0001 110.5C43.8362 98.258 41.7231 89.3018 40.5001 68.7973Z"
        fill="#323232"
      />
      <path
        d="M85.6365 198.566C101.781 226.54 140.874 224.35 153.064 198.821C153.396 198.127 154.144 197.922 154.623 198.139C181.582 210.357 199.284 223.353 211.373 240.321C223.476 257.308 230.102 278.482 234.512 307.356C234.604 307.958 234.132 308.5 233.538 308.5H3.47117C2.87815 308.5 2.40543 307.956 2.5001 307.354C7.26355 277.036 15.0978 255.611 27.931 238.851C40.765 222.091 58.7469 209.803 84.1803 198.032C84.6375 197.82 85.3031 197.988 85.6365 198.566Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.5 216.678L80.8151 256.71C81.173 257.216 81.9004 257.278 82.3386 256.839L118.763 220.415C119.165 220.013 119.821 220.026 120.206 220.444L153.701 256.811C154.12 257.265 154.845 257.235 155.225 256.749L186.5 216.678"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="120.5" cy="252.678" r="9.5" fill="#323232" />
      <circle cx="120.5" cy="279.678" r="9.5" fill="#323232" />
      <defs>
        <filter
          id="filter0_f_202_391"
          x="62"
          y="139"
          width="43"
          height="35"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_202_391"
          />
        </filter>
        <filter
          id="filter1_f_202_391"
          x="141"
          y="139"
          width="43"
          height="35"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_202_391"
          />
        </filter>
        <clipPath id="clip0_202_391">
          <rect
            width="137"
            height="190"
            fill="white"
            transform="translate(52 0.797363)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerFace;

export const EngineerFace = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const updateWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const calculateEyePosition = (eyeCenterX, eyeCenterY) => {
    const deltaX = mousePosition.x - eyeCenterX;
    const deltaY = mousePosition.y - eyeCenterY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const maxDistance = 5; // max distance the pupil can move within the eye

    if (distance < maxDistance) {
      return { x: deltaX, y: deltaY };
    } else {
      const ratio = maxDistance / distance;
      return { x: deltaX * ratio, y: deltaY * ratio };
    }
  };

  const leftEyeCenter = {
    x: windowSize.width / 2 - 50,
    y: windowSize.height / 2 - 50,
  };
  const rightEyeCenter = {
    x: windowSize.width / 2 + 50,
    y: windowSize.height / 2 - 50,
  };

  const eyePosition = calculateEyePosition(
    windowSize.width / 2,
    windowSize.height / 2
  );

  return (
    <svg
      width="auto"
      height="100"
      viewBox="0 0 237 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="83"
        y="133.203"
        width="68.2139"
        height="128.131"
        rx="13.7769"
        fill="white"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M84.8961 215.837V174.881H153.5C148.333 195.883 117.523 216.971 84.8961 215.837Z"
        fill="#7E7E7E"
        fill-opacity="0.45"
      />
      <path
        d="M77 129.5C77 141.189 66.4499 151 53 151C39.5501 151 29 141.189 29 129.5C29 117.811 39.5501 108 53 108C66.4499 108 77 117.811 77 129.5Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M160 129.5C160 141.189 170.55 151 184 151C197.45 151 208 141.189 208 129.5C208 117.811 197.45 108 184 108C170.55 108 160 117.811 160 129.5Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M185 125.331C185 145.672 179.073 161.171 168.053 171.6C157.016 182.045 140.583 187.678 119.03 187.678C97.4768 187.678 80.7676 182.044 69.4607 171.584C58.1775 161.146 52 145.647 52 125.331C52 104.972 58.2108 83.7262 69.7293 67.6204C81.2233 51.549 97.9224 40.678 119.03 40.678C140.131 40.678 156.56 51.5413 167.788 67.6029C179.045 83.7039 185 104.954 185 125.331Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M118.842 123.678C116.975 132.066 116 136.449 116 145.175C118.501 146.73 120.892 147.119 125 147.075"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83 134.047C85.9541 134.047 88.5904 132.45 90.4711 129.942C92.3516 127.435 93.5 123.994 93.5 120.214C93.5 116.434 92.3516 112.993 90.4711 110.486C88.5904 107.978 85.9541 106.381 83 106.381C80.0459 106.381 77.4096 107.978 75.5289 110.486C73.6484 112.993 72.5 116.434 72.5 120.214C72.5 123.994 73.6484 127.435 75.5289 129.942C77.4096 132.45 80.0459 134.047 83 134.047Z"
        fill="#323232"
        stroke="#323232"
        stroke-linecap="round"
      />
      <motion.circle
        animate={{ x: eyePosition.x, y: eyePosition.y }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
        cx="83"
        cy="120"
        r="4.5"
        fill="white"
        stroke="#323232"
      />
      <path
        d="M155 134.047C157.954 134.047 160.59 132.45 162.471 129.942C164.352 127.435 165.5 123.994 165.5 120.214C165.5 116.434 164.352 112.993 162.471 110.486C160.59 107.978 157.954 106.381 155 106.381C152.046 106.381 149.41 107.978 147.529 110.486C145.648 112.993 144.5 116.434 144.5 120.214C144.5 123.994 145.648 127.435 147.529 129.942C149.41 132.45 152.046 134.047 155 134.047Z"
        fill="#323232"
        stroke="#323232"
        stroke-linecap="round"
      />
      <motion.circle
        animate={{ x: eyePosition.x, y: eyePosition.y }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
        cx="156"
        cy="120"
        r="4"
        fill="white"
      />
      <path
        d="M110 158.678C113 162.345 120.8 167.478 128 158.678"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_f_204_580)">
        <ellipse cx="81.5" cy="155.703" rx="9.5" ry="5.5" fill="#FC9D80" />
      </g>
      <g filter="url(#filter1_f_204_580)">
        <ellipse cx="160.5" cy="155.703" rx="9.5" ry="5.5" fill="#FC9D80" />
      </g>
      <path
        d="M50.0901 71.3469C48.4443 52.2274 69.6561 11.859 88.2186 7.9612C88.2186 7.9612 143.405 -18.436 171.253 26.1511C199.101 70.7383 181.533 113.203 181.533 113.203C166.984 100.21 173.004 96.0332 171.253 79.4001C140.394 94.3008 112.3 90.8361 93.2355 75.2448C86.2119 87.8045 73.6696 90.403 69.9111 90.403C72.0655 101.068 60.1239 110.171 54.6053 112.77C48.8317 100.643 51.3173 89.1076 50.0901 71.3469Z"
        fill="#323232"
      />
      <g filter="url(#filter2_d_204_580)">
        <path
          d="M49.5078 47.2884C50.006 21.1398 71.3458 0.202637 97.4991 0.202637H137.18C163.215 0.202637 184.503 20.9573 185.165 46.9838L186 79.8779H48.887L49.5078 47.2884Z"
          fill="white"
        />
        <path
          d="M97.4991 2.20264H137.18C162.13 2.20264 182.532 22.0926 183.165 47.0346L183.949 77.8779H50.9254L51.5074 47.3265C51.9848 22.2675 72.4355 2.20264 97.4991 2.20264Z"
          stroke="#323232"
          stroke-width="4"
        />
      </g>
      <path
        d="M148.583 33.5921V77.8779H87.8214V33.5921C87.8214 16.8132 101.423 3.21118 118.202 3.21118C134.981 3.21118 148.583 16.8132 148.583 33.5921Z"
        fill="#323232"
        fill-opacity="0.38"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M16 80.2027C16 77.9746 17.8062 76.1685 20.0342 76.1685H184.988V80.2027H16V80.2027Z"
        fill="#323232"
      />
      <path
        d="M85.6365 200.566C101.781 228.54 140.874 226.35 153.064 200.821C153.396 200.127 154.144 199.922 154.623 200.139C181.582 212.357 199.284 225.353 211.373 242.321C223.476 259.308 230.102 280.482 234.512 309.356C234.604 309.958 234.132 310.5 233.538 310.5H3.47117C2.87815 310.5 2.40543 309.956 2.5001 309.354C7.26355 279.036 15.0978 257.611 27.931 240.851C40.765 224.091 58.7469 211.803 84.1803 200.032C84.6375 199.82 85.3031 199.988 85.6365 200.566Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.8379 214.178L57.8379 273.178"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M72.8379 207.178L72.8379 266.178"
        stroke="#323232"
        stroke-width="4"
      />
      <line
        x1="177.838"
        y1="212.178"
        x2="177.838"
        y2="273.178"
        stroke="#323232"
        stroke-width="4"
      />
      <line
        x1="162.838"
        y1="204.178"
        x2="162.838"
        y2="265.178"
        stroke="#323232"
        stroke-width="4"
      />
      <path
        d="M55.8379 256.178H180.838C186.361 256.178 190.838 260.655 190.838 266.178V310.178H45.8379V266.178C45.8379 260.655 50.315 256.178 55.8379 256.178Z"
        fill="white"
        stroke="#323232"
        stroke-width="4"
      />
      <circle cx="63.7922" cy="271.647" r="9" fill="#323232" />
      <circle cx="169.792" cy="271.647" r="9" fill="#323232" />
      <defs>
        <filter
          id="filter0_f_204_580"
          x="60"
          y="138.203"
          width="43"
          height="35"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_204_580"
          />
        </filter>
        <filter
          id="filter1_f_204_580"
          x="139"
          y="138.203"
          width="43"
          height="35"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_204_580"
          />
        </filter>
        <filter
          id="filter2_d_204_580"
          x="44.887"
          y="0.202637"
          width="145.113"
          height="87.6753"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0 0.0916667 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_580"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_204_580"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

{
  /* <motion.div
  className="w-5 h-5 bg-black rounded-full"
  animate={{ x: eyePosition.x, y: eyePosition.y }}
  transition={{ type: "spring", stiffness: 300, damping: 20 }}
></motion.div>; */
}
