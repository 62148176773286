import React, { useEffect, useState } from "react";
import "./index.css";
import "./App.css";
import { AboutMe, AboutUs } from "./components/layouts/AboutMe";
// import SolarIntegrationC1 from "./components/layouts/SolarIntegrationC1";
import { AirTravelResearchC2 } from "./components/layouts/AirTravelResearchC2";
import { CaseStudyList } from "./components/layouts/CaseStudyList";
import { CovidCare } from "./components/layouts/CovidCare";
import { ImageComparison } from "./components/layouts/ImageComparison";
import { MyLibrary } from "./components/layouts/MyLibrary";
import { Trandscend } from "./components/layouts/Trandscend";
import { Loader } from "./components/ui/Loader";
import Home from "./screens/Home";
import { Routes, Route } from "react-router-dom";

// GA Tracking ID
// ReactGAImplementation.initialize(TRACKING_ID);
function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AirTravelResearchC2" element={<AirTravelResearchC2 />} />
        <Route path="/CovidCare" element={<CovidCare />} />
        <Route path="/CaseStudyList" element={<CaseStudyList />}></Route>
        <Route path="/ImageComparison" element={<ImageComparison />}></Route>
        <Route path="/Trandscend" element={<Trandscend />}></Route>
        <Route path="/AboutMe" element={<AboutMe />}></Route>
        <Route path="/MyLibrary" element={<MyLibrary />}></Route>
      </Routes>
    </>
  );
}

export default App;
