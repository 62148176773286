import styled from "styled-components";

export const ParentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 90vh;
  background-color: #000;
  padding-left: 1vw;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50vh;
    padding: 1.5rem;
    /* background-color: bl; */
    /* justify-content: flex-start; */
  }
`;

export const Header = styled.p`
  color: #fff;

  font-size: 24pt;
  font-weight: bolder;

  width: 600px;

  @media (max-width: 720px) {
    display: flex;
    align-items: left;
    justify-content: flex;
    width: 80vw;
    font-size: 1.5rem;
  }
`;

export const MainImage = styled.img`
  height: 40vh;
  object-fit: cover;
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: 0;
  
  

  @media (max-width: 720px) {
    width: 50vw;
    height: auto;
    object-fit: cover;
    margin-top: 1rem;
    display: none;
  }
`;

export const Para = styled.p`
  color: #fafafa;
  font-size: 16pt;
  font-weight: regular;

  width: 40vw;

  @media (max-width: 720px) {
    width: 90vw;
    font-size: 14pt;
  }
`;
