import styled from "styled-components";

export const ListWrapper = styled.div`
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: 1fr 1fr;
  /* padding: 1rem; */
  /* background-color: red; */
  /* background-color: #191919; */

  gap: 2rem;
  margin-top: 2rem;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  /* gap: 10rem; */
`;

export const ParentContainer = styled.div`
  height: auto;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  background-color: #0f0f0f;

  background-size: 30%;
  z-index: 0;
  /* margin: 1rem; */
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    /* opacity: 0.4; */
    /* transform: translateY(-10px); */
    /* transform: translateX(10px); */
    /* cursor: pointer; */
  }

  @media (max-width: 720px) {
    /* border-bottom: 0.2px solid grey;   */
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;
  }
`;

export const ImageContainer = styled.div`
  width: 170%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  z-index: 10;

  @media (max-width: 720px) {
    /* display: flex; */
    width: 100%;
    /* background-color: blue; */
    height: auto;
    /* background-color: red; */
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px;

  @media (max-width: 720px) {
    padding: 16px;
    height: auto;
    gap: 0.2rem;
    /* background-color: yellow; */
  }
`;

export const ProjectName = styled.h1`
  justify-content: left;
  font-weight: normal;
  font-size: 16px;
  /* color: white; *
  font-weight: bold;
  line-height: normal;
  text-transform: ;
  
  /* letter-spacing: 0.01rem; */
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const ClientName = styled.h4`
  color: white;
  justify-content: left;
  font-size: 12pt;
  font-weight: bold;
  padding-top: 0.2rem;
  background-color: black;
  width: 150px;

  @media (max-width: 720px) {
    padding-top: 0.3rem;
  }
`;

export const ProjectDescription = styled.p`
  font-size: 16px;
  /* color: #fff; */
  font-weight: normal;
  z-index: 10;

  @media (max-width: 720px) {
    font-size: 10pt;
  }
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 320px;
  object-fit: cover;
  z-index: 10;
  background-color: black;

  @media (max-width: 720px) {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* aspect-ratio: inherit; */
    /* background-color: red; */
  }
`;

export const Role = styled.h2`
  font-size: 16px;
  width: 100%;
  /* opacity: 1; */
  /* color: #b0b0b0; */
  font-weight: normal;
  /* text-transform: lowercase; */
  font-family: avenir;

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const Head = styled.h1`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 48px;
  color: hsl(var(--nextui-foreground1));
  font-weight: 900;
  font-family: Avenir;
`;
