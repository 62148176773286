import { Airplane } from "../Icons/Airplane";
import {
  Body,
  Body2,
  Sp2,
  Sp3,
  Subheading,
} from "../styles/CaseStudyDetail.styled";
import React from "react";

export const InfoCard = ({text}) => {
  return (
    <div className=" flex flex-col w-[auto] p-[24px] h-[auto] bg-[#080F14] rounded rounded-lg border-[#294F72] border-2">
      <div className="flex gap-2">
        {" "}
        <Airplane />
        <Subheading style={{ color: "#7BBFFF" }}> Note</Subheading>
      </div>
      <Sp2 />
      <Body2 style={{ width: "100%", maxWidth: "none" }}>{text}</Body2>
    </div>
  );
};
