import {
  HanddrawnArrow,
  HanddrawnArrow1,
  HanddrawnArrow2,
  HanddrawnArrow3,
} from "../../Icons/HanddrawnArrow";
import DjImage from "../../assets/AboutMe/DJ Competetion.webp";
import Event3 from "../../assets/AboutMe/Design Event 3.webp";
import Event1 from "../../assets/AboutMe/Design Evnet 1.webp";
import Event2 from "../../assets/AboutMe/DesignEvent 2.webp";
import HikeImages from "../../assets/AboutMe/Hike Image.webp";
import { TitleCircle } from "../../styles/CaseStudyDetail.styled";
import {
  Body,
  Heading,
  Sp1,
  Sp2,
  Sp3,
  Sp4,
  Sp5,
  Sp6,
  Subheading,
  Title,
} from "../../styles/CaseStudyDetail.styled";
import { ButtonV3 } from "../Buttons";
import { Navbar } from "../Navbar";
import { BubbleText } from "../ui/BubbleText";
import { StickyScroll } from "../ui/StickyScroll";
import { motion, useSpring, useScroll } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

// const content = [
//   {
//     title: "Designing Harmonious Digital Journeys 🖌️",
//     description:
//       "Meet Punit, the UX maestro blending beats with bytes! With a background in Computer Science and a DJ's soul, I orchestrate digital experiences infused with rhythm and creativity. ",
//     content: (
//       <img
//         src={ProfilePicture}
//         width="200"
//         height="200"
//         className="h-full w-full object-scale-down"
//         alt="linear board demo"
//       />
//     ),
//   },
//   {
//     title: "Bridging Worlds at Design Events 🌎",
//     description:
//       "I'm all about mingling with fellow designers, sharing ideas, and maybe even causing a little mischief along the way. I believe in keeping the creativity flowing and see where the fun takes us!",
//     content: (
//       <img
//         src={DesignEvent}
//         width="200"
//         height="200"
//         className="h-full w-full object-scale-down"
//         alt="linear board demo"
//       />
//     ),
//   },
//   {
//     title: "A DJ/Producer 🎧",
//     description:
//       "My love for music isn't just a hobby; it's a secret ingredient in my design process. From crafting seamless transitions on the dance floor to creating intuitive interfaces, my DJ experience teaches me how to pace interactions and keep users engaged.",
//     content: (
//       <img
//         src={DjIMages}
//         width="200"
//         height="200"
//         className="h-full w-full object-scale-down"
//         alt="linear board demo"
//       />
//     ),
//   },
//   {
//     title: "Sometimes Hiking Paths 🏔️",
//     description:
//       "When I'm not crafting digital experiences, you can bet I'm exploring nature's wonders, finding inspiration in every trail. Hiking teaches me resilience and creativity, shaping my approach to design. ",
//     content: (
//       <img
//         src={HikeImages}
//         width="200"
//         height="200"
//         className="h-full w-full object-scale-down"
//         alt="linear board demo"
//       />
//     ),
//   },
// ];

export const AboutMe = () => {
  const navigation = useNavigate();
  return (
    <motion.div className="  flex flex-row items-center px-[4vw] ">
      <Navbar />

      <div className="my-[4rem] flex flex-col gap-[6rem] w-[100%] ">
        {/* <div className="font-black uppercase text-[48px] text-foreground1 w-[70vw] " style={{}}>
          The first tool I learned was Photoshop in 2013. I used it to put my
          friend in a porsche 🏎️.
        </div> */}
        <div>
          <div className="flex  items-center gap-2 h-[2rem] ">
            <TitleCircle />
            <h1
              className=" horror-dark font-bold  uppercase   text-left text-title     "
              style={{
                fontFamily: "Avenir",

                fontSize: "16px",
              }}
            >
              At the age of 15
              <br />
            </h1>
          </div>
          <BubbleText
            text={
              "First tool I learned was Photoshop in 2013. I used it to put my friend in a porsche ."
            }
          />
        </div>
        <div className="flex flex-row gap-[1rem]  items-center mt-[15vh]  sm:flex-col">
          <div className="relative">
            <div className="flex gap-[0px] items-top absolute -top-[150px] left-[50px] ">
              <div className="pt-[20px]">
                <HanddrawnArrow2 />
              </div>
              <div
                className="w-[230px] text-center items-top content-top text-[20px] uppercase bottom-[10px] sm:text-[14px]"
                style={{ fontFamily: "Architects Daughter" }}
              >
                2016, competing in my first war of DJS
              </div>
            </div>
            <img
              className="w-[252px] h-[346px] object-fill bg-[image-class] rounded-md"
              src={DjImage}
            />
          </div>

          <div>
            <img
              className="w-[252px] h-[346px] object-fill bg-[image-class] rounded-md items-center"
              src={Event1}
            />
            <div className="flex gap-[0px] items-baseline absolute px-[50px] py-[20px]">
              <HanddrawnArrow1 />
              <div
                className="w-[230px] text-center text-[20px] uppercase sm:text-[14px]"
                style={{ fontFamily: "Architects Daughter" }}
              >
                {" "}
                2022, Attended first UX conference
              </div>
            </div>
          </div>
          <img
            className="w-[252px] h-[346px] object-fill bg-[image-class] rounded-md"
            src={Event2}
          />
          <img
            className="w-[252px] h-[346px] object-fill bg-[image-class] rounded-md"
            src={Event3}
          />
          <div className="relative">
            <img
              className="w-[252px] h-[346px] object-fill bg-[image-class] rounded-md"
              src={HikeImages}
            />
            <div className="flex flex-col gap-[0px] items-center -bottom-[150px] absolute ">
              <HanddrawnArrow3 />
              <div
                className="w-[230px] text-center text-[20px] uppercase sm:text-[14px]"
                style={{ fontFamily: "Architects Daughter" }}
              >
                2023, Hiked “Kheerganga” trek in himachal
              </div>
            </div>
          </div>
        </div>
        <Sp6 />
        <Sp3 />

        <div>
          <Title> Hello, Punit Here</Title>
          <Sp2 />
          <Body>
            <span className="font-normal  text-foreground1">
              A Product Designer, Problem Solver and Coder. Always observing my
              surroundings. I like to read too, My latest read is “ Just Enough Design”. Sometimes you
              can find me Djing and playing synths. Sometimes hiking paths too.
            </span>
          </Body>
          <Sp1 />
          <Body>
            Currently developing some interesting interactions in my {""}
            <span
              className="font-bold text-foreground1 cursor-pointer"
              onClick={() => {
                navigation("/MyLibrary");
              }}
            >
              Lab.
            </span>{" "}
            <br />
            Kill the button below if you have any interesting idea to
            collaborate or any other type of enquiry.
          </Body>
          <Sp4 />
          <ButtonV3
            label={"SAY HEY!!"}
            action={() =>
              (window.location = "mailto:workwithpunitgupta@gmail.com")
            }
          />
        </div>

        <div className=" flex flex-row justify-between gap-[20px] w-full sm:overflow-scroll sm:grid sm:grid-cols-2 sm:gap-16">
          <div className="w-[60vw] flex flex-col gap-[24px] align-right ">
            <Title>EXPERIENCE</Title>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>Senior UX Designer</Body>
              <Body style={{ width: "100%" }}>Think Design Collaborative</Body>
              <Body style={{ width: "100%" }}>Jan 2024 - Present</Body>
            </div>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}> UX Designer</Body>
              <Body style={{ width: "100%" }}>Think Design Collaborative</Body>
              <Body style={{ width: "100%" }}>Mar 2021 - Jan 2024</Body>
            </div>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>UX Designer</Body>
              <Body style={{ width: "100%" }}>Apporio Infolabs</Body>
              <Body style={{ width: "100%" }}>Mar 2020 - Feb 2021</Body>
            </div>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>UX Design Intern</Body>
              <Body style={{ width: "100%" }}>McKinley & Rice</Body>
              <Body style={{ width: "100%" }}>Oct 2019 - Jan 2020</Body>
            </div>
          </div>
          <div className="w-[60vw] flex flex-col gap-[24px] align-right">
            <Title>SKILLS & INTEREST</Title>

            <div className="flex flex-col gap-[4px]">
              <Body style={{ width: "auto" }}>User Centred Design</Body>
              <Body style={{ width: "auto" }}>Data Visualisation</Body>

              <Body style={{ width: "auto" }}>UX Research</Body>

              <Body style={{ width: "auto" }}>Visual Design</Body>

              <Body style={{ width: "auto" }}>Wireframing</Body>

              <Body style={{ width: "auto" }}>Interaction Design</Body>
            </div>
          </div>
          <div className="w-[60vw] flex flex-col gap-[24px] align-right">
            <Title>EDUCATION</Title>

            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>
                Poornima Collage , 2015-2019
              </Body>
              <Body style={{ width: "100%" }}>B.Tech, Computer Science</Body>
            </div>
          </div>
          <div className="w-[60vw] flex flex-col gap-[24px] align-right">
            <Title>ACHIEVEMENT </Title>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>Employee of the Quarter</Body>
              <Body style={{ width: "100%" }}>
                Think Design Collaborative (Havas Media)
              </Body>
              <Body style={{ width: "100%" }}>GE Renewables - 2022</Body>
            </div>
            <div className="flex flex-col gap-[4px]  items-start  w-full">
              <Body style={{ width: "100%" }}>Employee of the Quarter</Body>
              <Body style={{ width: "100%" }}>
                Think Design Collaborative (Havas Media)
              </Body>
              <Body style={{ width: "100%" }}>Transcend - 2021</Body>
            </div>
          </div>
        </div>
        <Sp6 />

        {/* <DrawingBoard/> */}
      </div>

      {/* <StickyScroll content={content} /> */}
    </motion.div>
  );
};
