const AnimatedSVG = () => (
  <svg
    width="100"
    height="auto"
    viewBox="0 0 177 196"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {`
        @keyframes waveHand {
          0% { transform: rotate(0deg); }
          25% { transform: rotate(15deg); }
          50% { transform: rotate(0deg); }
          75% { transform: rotate(-15deg); }
          100% { transform: rotate(0deg); }
        }
        .hand {
          transform-origin: 130px 100px;
          transition: transform 0.3s ease;
        }
        svg:hover .hand {
          animation: waveHand 1s ease-in-out infinite;
        }
      `}
    </style>
    <path
      d="M105.456 99.2838C102.226 106.664 97.6661 116.634 90.1861 120.594C84.4661 123.594 77.5361 124.274 71.1861 123.884C48.7761 122.524 30.0861 105.444 26.3461 83.0938C25.6161 78.6938 24.1161 72.8438 25.2161 68.3738C26.2161 64.2938 31.4861 69.0138 32.1061 71.0238C33.1061 74.1638 32.7861 79.3338 30.8061 82.1438C28.1061 85.9538 22.6661 84.5438 20.6361 80.9838C17.0161 74.6338 17.0861 65.9838 17.2061 58.9838C17.4661 45.4138 21.9661 31.3238 31.1261 21.0938C42.8661 7.99379 64.9161 -0.226214 82.6261 1.65379C94.9761 2.96379 110.496 8.39378 116.626 20.1438C121.386 29.2838 122.276 48.4938 109.966 52.3338C102.966 54.5138 94.2661 52.6038 90.2361 46.0538C88.6661 43.5038 85.6861 34.2738 89.9561 32.2938C92.0861 31.2938 92.0961 36.5138 91.9561 37.3538C91.3461 42.1638 85.2561 47.2438 80.2461 46.3538C75.6461 45.5638 70.6561 35.1738 76.2461 32.3538C77.7561 31.6038 78.2461 34.1438 78.2461 35.1538C78.2461 37.4238 76.6361 39.1538 74.9861 40.4938C69.1761 45.2438 58.7661 45.8538 53.4161 39.9838C51.0243 37.0481 49.5899 33.4498 49.3061 29.6738C49.2361 28.9138 48.7761 25.4938 50.0961 25.7438C56.2261 26.9138 56.0961 33.1938 53.8361 37.8038C50.7061 44.2438 42.7261 47.7338 36.1561 49.2338C31.8461 50.2338 27.5561 49.5438 25.6561 45.1238"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M44.6761 51.0138C56.3961 47.5838 59.1061 63.0138 58.7861 71.3838C58.4762 76.5724 57.4048 81.6871 55.6061 86.5638C55.0758 88.1707 54.3681 89.7136 53.4961 91.1638C53.0761 91.8238 51.1262 92.8938 51.9162 92.9138C55.4062 92.9738 58.2561 95.4138 61.6261 95.9738C67.6261 96.9738 74.2261 94.7038 79.5161 92.1038"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M78.9261 49.0438C74.9261 49.9938 70.5561 52.6338 69.1461 55.4738C68.0097 57.8472 67.4121 60.4424 67.3961 63.0738C67.3632 65.6849 67.8176 68.2793 68.7361 70.7238C75.4461 88.6138 103.786 81.2238 105.376 63.7838C106.686 49.4538 85.7961 41.2338 76.2061 51.1838C68.6761 59.0038 71.5261 74.7838 81.7461 78.8838C85.7287 80.3693 90.1134 80.3693 94.0961 78.8838"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.546 55.4538C81.816 57.6838 81.486 61.3438 82.266 64.8538C83.366 69.7838 89.946 72.5838 93.796 68.8538C95.996 66.7438 97.3861 63.2138 96.5361 60.1938C95.3161 55.7038 90.136 52.7038 85.546 55.4538Z"
      fill="White"
    />
    <g className="hand">
      <path
        d="M124.166 65.3938C129.366 70.5538 126.506 79.2638 121.416 83.4538C119.036 85.4038 114.096 87.4538 111.586 84.6138C109.076 81.7738 110.206 77.0939 111.586 74.1939C115.026 67.0739 125.496 58.0438 132.766 65.5738C138.506 71.5238 136.146 81.8039 130.356 86.8139C128.916 88.0439 125.066 91.4638 122.866 90.6138C120.466 89.6838 123.036 84.3539 123.756 83.1839C126.636 78.5839 133.276 71.7538 139.546 74.7638C147.426 78.5638 141.896 90.8738 137.956 94.7638C136.816 95.8838 133.706 98.9638 131.786 98.6038C129.866 98.2438 130.786 91.9339 131.076 90.7539C132.176 86.0539 136.946 79.3738 142.506 79.4238C148.406 79.4238 149.866 86.4838 148.626 91.0238C147.546 94.9638 142.446 98.0238 138.436 97.9538"
        stroke="White"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M120.946 94.1838C120.468 96.4243 120.353 98.727 120.606 101.004C120.866 102.844 122.286 104.774 123.426 106.164C131.106 115.524 146.896 108.664 151.906 99.5538C154.686 94.4938 156.336 88.3838 155.546 82.5538C152.656 61.5538 124.876 54.0938 110.166 66.9538"
        stroke="White"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M102.316 76.5538C99.3161 81.1238 98.0461 87.6538 102.316 92.0338C104.576 94.3738 107.526 95.4338 110.126 97.2638C112.186 98.7238 113.916 103.554 116.616 102.414C124.006 99.2738 120.516 88.0338 114.846 84.9738C111.805 83.4419 108.429 82.6959 105.026 82.8038C104.586 82.8038 100.906 84.1238 101.776 84.8938C102.986 85.9538 106.446 83.4138 107.326 82.7538"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M124.146 109.774C123.376 111.894 122.496 113.574 122.296 115.774C121.426 125.334 131.036 125.104 137.476 123.424C148.106 120.654 156.996 115.164 163.026 105.764C165.206 102.384 167.516 98.3139 167.716 94.1939C167.716 93.5839 167.716 90.5939 166.636 90.4939C163.556 90.2239 162.636 94.6538 163.106 96.8338C165.106 105.514 167.716 114.074 169.666 122.784C174.896 146.084 177.996 165.784 172.306 189.504"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M121.666 123.194C122.318 137.587 121.924 152.008 120.486 166.344C119.366 176.284 116.776 185.934 115.936 195.914"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M39.8561 120.614C15.1861 138.474 2.93609 164.614 0.996094 194.554"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M57.5861 124.624C56.9761 132.084 67.0261 141.444 77.8961 140.234C86.8961 139.234 95.5261 131.544 94.8261 123.994"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M58.5461 106.104C65.9561 113.424 75.5961 112.044 82.4361 104.934"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1761 60.1038C40.5861 62.0438 40.0461 65.1038 41.8261 67.0438C42.2929 67.495 42.8484 67.8442 43.4574 68.0691C44.0664 68.294 44.7156 68.3898 45.3636 68.3503C46.0116 68.3108 46.6443 68.1369 47.2214 67.8397C47.7986 67.5424 48.3076 67.1284 48.7161 66.6238C50.5661 64.4138 50.5561 62.2838 49.6661 60.6238C48.5761 58.5238 44.1261 57.7338 42.1761 60.1038Z"
      fill="White"
    />
    <path
      d="M101.866 73.7038C104.866 75.7638 107.376 78.4338 110.366 80.4838"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M104.416 67.2238C107.339 68.9038 110.373 70.3838 113.496 71.6538"
      stroke="White"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default AnimatedSVG;
