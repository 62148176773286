import { HomeIcon } from "../Icons/HomeIcon";
import { MailIcon } from "../Icons/MailIcon";
import { MoonIcon } from "../Icons/MoonIcon";
import { SunIcon } from "../Icons/SunIcon";
import { TopIcon } from "../Icons/TopIcon";
import { cn } from "../utils/cn";
import { Button, Switch } from "@nextui-org/react";
import { VisuallyHidden, useSwitch } from "@nextui-org/react";
import { motion, useSpring, useMotionValue } from "framer-motion";
import { useTheme } from "next-themes";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";


function ButtonWithGradientAndAnimation({ label, action }) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const button = document.getElementById("shimmerButton");
    if (button) {
      if (isHovered) {
        button.classList.add("animate-shimmer");
      } else {
        button.classList.remove("animate-shimmer");
      }
    }
  }, [isHovered]);

  return (
    <button
      id="shimmerButton"
      className="inline-flex h-10 w-max items-center justify-center rounded-md border border-gray-900 px-6 font-bold text-white text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
      style={{
        backgroundImage:
          "linear-gradient(110deg, #000103 45%, #1e2631 55%, #000103)",
        backgroundSize: "200% 100%",
        transition: "background-position 2s linear",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={action}
    >
      {label}
    </button>
  );
}

export default ButtonWithGradientAndAnimation;

export const HomeButton = () => {
  const navigation = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",

      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  return (
    <div
      className="dark flex flex-col gap-4 "
      style={{ position: "fixed", zIndex: 1200, left: "90%", bottom: "40px" }}
    >
      <Button
        color="default"
        variant="solid"
        startContent={<HomeIcon />}
        size="lg"
        onClick={() => navigation("/")}
        isIconOnly
      ></Button>
      <Button
        color="default"
        variant="solid"
        startContent={<TopIcon />}
        size="lg"
        onClick={scrollToTop}
        isIconOnly
      ></Button>
      <ThemeSwitcher />
    </div>
  );
};

export const ButtonV2 = ({ label, action }) => {
  const tickSound = new Audio("/Click2.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="relative group/btn flex items-center justify-center w-auto rounded-md h-10 font-normal hover:text-white ease-out duration-500"
        onClick={handleClick}
      >
        <span
          className=" uppercase text-foreground2 font-medium text-sm text-center w-max hover:text-foreground1 ease-out duration-500"
          style={{ fontFamily: "" }}
        >
          {label}
        </span>
      </button>
    </>
  );
};
export const ButtonV3 = ({ label, action }) => {
  const tickSound = new Audio("/Click2.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="  flex items-center  justify-center w-[auto] px-[12px] rounded-[8px] border-1 border-background2 bg-[]  h-10  hover:text-white hover:bg-[#232323] text-foreground2  text-[13px] text-center  hover:text-foreground1 font-medium ease-out duration-500"
        onClick={handleClick}
        style={{ fontFamily: "" }}
      >
        {label}
      </button>
    </>
  );
};

export const IconButton = ({ icon, action }) => {
  const tickSound = new Audio("/Click.mp3");
  tickSound.volume = 0.3;
  const handleClick = () => {
    tickSound.play();
    action();
  };
  return (
    <>
      <button
        className="relative flex items-center justify-center h-[70px] w-[70px]  rounded-full border-[1px] border-background2 hover:bg-background2 ease-out 
        duration-500 "
        onClick={handleClick}
      >
        {icon}
      </button>
    </>
  );
};
export const Status = ({ input }) => {
  return (
    <>
      <div className="flex h-8 w-max  bg-background text-foreground rounded-[0.25rem] px-0 py-2  text-[14px] font-bold items-center gap-x-2 border-0 border-background2 sm:text-[14px] ">
        <div className="rounded-[0.25rem] bg-lime-500 h-2 w-2"></div>
        <p className="t-white uppercase"> {input}</p>
      </div>
    </>
  );
};

export function ThemeSwitcher() {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();
  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    if (localTheme) {
      setTheme(localTheme);
    }
  }, [setTheme]);

  return (
    <div>
      {/* The current theme is :{theme}
      <button onClick={() => setTheme("light")}>Light Mode</button>
      <button onClick={() => setTheme("dark")}>Dark Mode</button> */}
      <Switch
        // checked={theme === "dark"}
        onChange={() => setTheme(theme == "light" ? "dark" : "light")}
        size="sm"
        color="default"
        isSelected={theme == "dark"}
        thumbIcon={({ isSelected, className }) =>
          theme == "light" ? (
            <SunIcon className={className} />
          ) : (
            <MoonIcon className={className} />
          )
        }
      ></Switch>
    </div>
  );
}

export const ThemeSwitch = (props) => {
  const { theme, setTheme } = useTheme();
  const {
    Component,
    slots,
    isSelected,
    getBaseProps,
    getInputProps,
    getWrapperProps,
  } = useSwitch(props);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    console.log("localTheme", localTheme);
    if (localTheme) {
      setTheme(localTheme);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <Component {...getBaseProps()}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <div
          {...getWrapperProps()}
          className={slots.wrapper({
            class: [
              "w-8 h-8",
              "flex items-center justify-center",
              "rounded-lg ",
            ],
          })}
          checked={theme === "dark"}
          onClick={() => setTheme(theme == "light" ? "dark" : "light")}
        >
          {theme == "light" ? <SunIcon /> : <MoonIcon />}
        </div>
      </Component>
    </div>
  );
};

export const Chip = ({ name }) => {
  return (
    <div
      className="bg-background3 rounded-lg w-max px-3 py-1 text-foreground2 text-sm"
      style={{ fontFamily: "PPNeueMontrealMono" }}
    >
      {" "}
      {name}{" "}
    </div>
  );
};

export const viewButton = () => {
  return (
    <div className="">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 16H9.41L17.71 7.71C17.8983 7.5217 18.0041 7.2663 18.0041 7C18.0041 6.7337 17.8983 6.47831 17.71 6.29C17.5217 6.1017 17.2663 5.99591 17 5.99591C16.7337 5.99591 16.4783 6.1017 16.29 6.29L8 14.59V7C8 6.73479 7.89464 6.48043 7.70711 6.2929C7.51957 6.10536 7.26522 6 7 6C6.73478 6 6.48043 6.10536 6.29289 6.2929C6.10536 6.48043 6 6.73479 6 7V17C6.00158 17.1307 6.02876 17.2598 6.08 17.38C6.18147 17.6244 6.37565 17.8185 6.62 17.92C6.74022 17.9712 6.86932 17.9984 7 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17C18 16.7348 17.8946 16.4804 17.7071 16.2929C17.5196 16.1054 17.2652 16 17 16Z"
          fill="white"
        />
      </svg>
    </div>
  );
};