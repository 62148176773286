import React from "react";

export const Airplane = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8699 9.10059C19.7954 8.9293 19.6744 8.78231 19.5206 8.67627C19.3668 8.57022 19.1865 8.50936 18.9999 8.50059H14.4199L15.6899 3.76059C15.7303 3.61226 15.736 3.45661 15.7065 3.30572C15.6771 3.15483 15.6132 3.01278 15.5199 2.89059C15.4267 2.76957 15.3071 2.6715 15.1701 2.6039C15.0332 2.53629 14.8826 2.50095 14.7299 2.50059H7.72988C7.50411 2.49285 7.28238 2.56179 7.1008 2.69616C6.91922 2.83053 6.78848 3.02242 6.72988 3.24059L4.04988 13.2406C4.00943 13.3889 4.00373 13.5446 4.03321 13.6955C4.06269 13.8463 4.12657 13.9884 4.21988 14.1106C4.31406 14.2329 4.4353 14.3318 4.5741 14.3995C4.71289 14.4672 4.86547 14.5018 5.01988 14.5006H8.88988L7.07988 21.2406C7.02057 21.4579 7.0364 21.6889 7.12478 21.8961C7.21316 22.1034 7.36893 22.2747 7.56684 22.3823C7.76474 22.4899 7.9932 22.5276 8.21518 22.4892C8.43716 22.4507 8.63967 22.3385 8.78988 22.1706L19.6899 10.1706C19.8197 10.0285 19.9057 9.85192 19.9376 9.66212C19.9695 9.47232 19.946 9.27735 19.8699 9.10059V9.10059ZM10.0799 17.7806L11.1499 13.7806C11.1903 13.6323 11.196 13.4766 11.1665 13.3257C11.1371 13.1748 11.0732 13.0328 10.9799 12.9106C10.8867 12.7896 10.7671 12.6915 10.6301 12.6239C10.4932 12.5563 10.3426 12.521 10.1899 12.5206H6.34988L8.48988 4.50059H13.4199L12.1499 9.24059C12.1091 9.39164 12.1043 9.55016 12.136 9.70339C12.1676 9.85662 12.2348 10.0003 12.332 10.1228C12.4293 10.2454 12.554 10.3434 12.696 10.409C12.8381 10.4746 12.9935 10.506 13.1499 10.5006H16.7199L10.0799 17.7806Z"
        fill="#7BBFFF"
      />
    </svg>
  );
};
