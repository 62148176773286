import AnimatedSVG from "../Icons/Flask";
import { HeroSection } from "../components/HeroSection";
import { Navbar } from "../components/Navbar";
import { CaseStudyList } from "../components/layouts/CaseStudyList";
import { FAQ } from "../components/layouts/FAQ";
import { Footer } from "../components/layouts/Footer";
import React from "react";

const Home = () => {
  /* cursor*/
  return (
    <>
      <div className=" bg-background align-center px-[4vw]">
        <Navbar />
        

        <HeroSection />

        <CaseStudyList />
        <FAQ />
        <Footer />
        {/* <Presentation /> */}
      </div>
    </>
  );
};

export default Home;
// export default transition(Home);
