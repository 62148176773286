import { Body, Sp1, Title } from "../styles/CaseStudyDetail.styled";
import { ButtonV2, ButtonV3 } from "./Buttons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PasswordModal = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "P@ssw0rd") {
      onCorrectPassword();
    } else {
      setError("Wrong password");
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-background">
      {/* <div className="absolute inset-0  opacity-50 backdrop-filter backdrop-blur-lg"></div> */}
      <div className=" flex flex-col content-between z-50 bg-background3 w-[40vw] h-[auto] p-8 rounded-lg shadow-xl">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-between h-[auto] gap-[2rem]"
        >
          <div className="">
            <Title>Enter Password</Title>
            <Sp1 />
            <Body style={{ width: "100%" }}>
              This case study is under NDA 😭. Please enter the password.
            </Body>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex flex-col gap-[0rem] pb-[1rem]">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-background p-4 border border-background2 rounded mb-4"
                placeholder="Password"
              />
              {error && <p className="text-red-400 mb-4">{error}</p>}
            </div>
            <div className="flex flex-row-reverse justify-between pt-[2rem]  border-background2 border-t-[1px]   ">
              <ButtonV3
                label={"SUBMIT"}
                type="submit"
                action={() => handleSubmit}
              />
              <ButtonV2 action={() => handleCancel()} label={"Cancel"} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const PasswordProtectedPage = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleCorrectPassword = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      {!isAuthenticated && (
        <PasswordModal onCorrectPassword={handleCorrectPassword} />
      )}
      {isAuthenticated && children}
    </>
  );
};

export default PasswordProtectedPage;
