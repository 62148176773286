import React from 'react'

export const ComingSoon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5745 17.9143H15.4255M17.9787 21H8.02128C7.45724 21 7 20.5395 7 19.9714V17.2237C7 17.0042 7.06977 16.7904 7.19909 16.6136L10.1281 12.6101C10.3935 12.2473 10.3935 11.7527 10.1281 11.3899L7.19909 7.38641C7.06977 7.20964 7 6.99584 7 6.77626V4.02857C7 3.46051 7.45724 3 8.02128 3H17.7234C18.2874 3 18.7447 3.46051 18.7447 4.02857V6.79608C18.7447 7.00337 18.6825 7.20582 18.5663 7.3769L15.8351 11.3971C15.5898 11.7581 15.5981 12.2359 15.8558 12.5881L18.8009 16.6136C18.9302 16.7904 19 17.0042 19 17.2237V19.9714C19 20.5395 18.5428 21 17.9787 21Z"
        // stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className='stroke-foreground1'
      />
    </svg>
  );
}
