import styled from "styled-components";

export const WarningWrapper = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    background-color: #fa6c19;
    color: white;
    position: fixed;
    z-index: 100;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;
