import { ChevronDown, ChevronUp } from "../../Icons/Chevron";
import Image1 from "../../assets/HeroSection/Himalyasss.webp";
import { Head } from "../../styles/CaseStudy.styled";
import {
  Body,
  Heading,
  Sp0,
  Sp1,
  Sp4,
  Subheading,
} from "../../styles/CaseStudyDetail.styled";
import { TitleCircle } from "../../styles/CaseStudyDetail.styled";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

export const FAQ = () => {
  const [open, setOpen] = useState(null);
  const questions = [
    {
      id: 1,
      question: "Why Designing?",
      Answer: `Long story short - In college I have been taught, "problem solving only means coding." But when I got to know about UX/Product design in 3rd year of college, I realized, "In the process, Problem solving starts way before than coding which is design and research." That part fascinated me more than coding, and I started exploring UX design and here I am designing for a big fortune 300 company, won 2 Employee of the quarter and now promoted to Senior UX designer. BUT Still learning everyday :)`,
      // Img: Image1,
    },
    {
      id: 2,
      question: "What kind of products you worked upon mostly",
      Answer:
        "Mostly I have worked upon Enterprise Level Products. From last 2.5 years I have been working for GE Vernova. They have been our client at Think. Before that I worked for transcend, another enterprise giant in the fintech market ",
      // Img: Image1,
    },
    {
      id: 3,
      question: "Are you open to relocate",
      Answer:
        "Yes, I am open to relocate anywhere in PAN india. I am also open to relocate outside india, In Europe and Japan ",
      // Img: Image1,
    },
    {
      id: 4,
      question: "Do you know coding",
      Answer:
        "Yes, 70% Designer & 30% Coder. I know React, Tailwind and Framer motion on an intermediate level. Coding is something which I have been doing alongside design. My formal background is also Computer Engineering. In I only developed this website from scratch using react and framer motion.",
      // Img: Image1,
    },
  ];

  return (
    <div className="bg-background w-full mt-20 ">
      <div className="flex  items-center gap-2 h-[2rem] ">
        <TitleCircle />
        <h1
          className=" horror-dark font-bold  uppercase   text-left text-title   "
          style={{
            fontFamily: "Avenir",

            fontSize: "14px",
          }}
        >
          Frequently Asked Questions
          <br />
        </h1>
      </div>
      {/* <Head className="text-foreground2"> Frequently Asked Questions</Head> */}

      <div className="flex flex-col gap-8 mt-10">
        {questions.map((q) => (
          <motion.div
            onClick={() => setOpen(open === q.id ? null : q.id)}
            key={q.id}
            className=" p-[2rem] flex flex-col w-[92vw] h-full justify-center items-left rounded-[0.25rem] border-solid border-background2 border-[1px] bottom-0 last:mb-0 cursor-pointer"
          >
            <div
              className="justify-between w-full flex cursor-pointer "
              onClick={() => setOpen(open === q.id ? null : q.id)}
            >
              <Subheading> {q.question}</Subheading>
              {open === q.id ? <ChevronUp /> : <ChevronDown />}
            </div>
            <AnimatePresence>
              {open === q.id && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <Sp4 />
                  <div className="flex flex-col items-left">
                    <Body style={{ color: "hsl(var(--nextui-foreground2))" }}>
                      {q.Answer}
                    </Body>
                    <img src={q.Img} className="h-auto w-20 aspect-auto" />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
